<template>
    <div class="row px-3 py-5 mx-2 table-container">
        <div class="col-12 header pb-3">
            <div class="checkbox-size">
                <template v-if="settings.selected.length == 0 || settings.selected.length == items.length">
                    <input type="checkbox" class="form-check-input" v-model="selectedRows"/>
                </template>
                <template v-else>
                    <input type="checkbox" class="form-check-input" v-on:change="deselectAll" :checked="true" :indeterminate="true"/>
                </template>
            </div>
            <div class="row data-column-width">
                <div v-for="(column, index) in columns" :index="index" :v-key="index" :class="'col-' + column.col ">
                    <TableHeader :name="column.userFriendly" :column="column.name" :sortable="column.sortable" :selected="selectedSort" @sort="changeSort"/>
                </div>
            </div>
        </div>
        <template v-if="!settings.loading">
            <ItemRow v-for="(item, index) in items" :item="item" :key="item.id" :index="index" :settings="settings" :columns="columns" :selected="isSelected(item.id)" @changeSelected="selectChangeRow"/>
        </template>
        <template v-else>
            <div class="col-12">
            <div class="d-flex justify-content-center my-5">
                <div class="spinner-border spinner-color" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div></div>
        </template>
    </div>
</template>

<script>
import ItemRow from "@/components/ItemRow.vue";
import TableHeader from "@/components/TableHeader.vue";

export default {
    props: {
        settings: {
            type: Object,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
    },
    components: {
        ItemRow,
        TableHeader,
    },
    data() {
        return {}
    },
    computed: {
        selectedSort: function () {
            return {
                column: this.settings.sortBy,
                direction: this.settings.sortDirection
            }
        },
        selectedRows: {
            get() {
                return this.isAllSelected
            },
            set() {
                if (this.isAllSelected) {
                    this.deselectAll()
                }
                else {
                    this.selectAll()
                }
            }
        },
        isAllSelected: function () {
            return this.settings.selected.length == this.items.length;
        }
    },
    methods: {
        changeSort: function (column, direction) {
            this.$emit('changeSort', column, direction);
        },
        isSelected: function (item_id) {
            return this.settings.selected.includes(item_id)
        },
        selectChangeRow: function (item_id, state) {
            this.$emit('selectChangeRow', item_id, state);
        },
        selectAll: function () {
            this.$emit('selectAll');
        },
        deselectAll: function () {
            this.$emit('deselectAll');
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.container {
    margin-top: 20px;
}

.table-container {
    min-width: 750px;
}

.header {
    font-weight: bold;
    background-color: $background-color;
    border-bottom: 2px solid black;
}

.item {
    div &:nth-of-type(odd) {
        background-color: $background-color;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-grey, 5%);
        }
    }

    &:nth-of-type(even) {
        background-color: $background-grey;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-grey, 5%);
        }
    }
}
</style>
