<template>
  <div class="col-12">
    <button type="button"
      class="btn btn-primary m-3"
      data-bs-toggle="modal"
      data-bs-target="#addBranch"
      @click="resetBranch">
      Založit novou pobočku
    </button>
    <div class="mx-4">
      <div class="row">
        <span class="col-1"><strong>ID</strong></span>
        <span class="col-3"><strong>Název</strong></span>
        <span class="col-2"><strong>Kraj</strong></span>
        <span class="col-2"><strong>Akce</strong></span>
      </div>
      <div v-for="(branch, index) in branches" :key="index" class="row item align-items-center">
        <span class="col-1">{{ branch.id }}</span>
        <span class="col-3"><strong>{{ branch.name }}</strong></span>
        <span class="col-2">{{ branch.parent.name }}</span>
        <div class="col-1">
          <button type="button"
            class="btn btn-sm btn-outline-secondary my-2"
            data-bs-toggle="modal"
            data-bs-target="#editBranch"
            @click="setBranch(branch)">
            Editovat
          </button>
        </div>
        <div class="col-2">
          <button type="button"
            class="btn btn-sm btn-outline-danger my-2"
            data-bs-toggle="modal"
            data-bs-target="#deleteBranch"
            @click="setBranch(branch)">
            Zrušit pobočku
          </button>
        </div>
      </div>
    </div>
  </div>
  <Popup :header="'Editovat pobočku'"
    :editable="true"
    id="editBranch"
    @save-data="updateBranch">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="branchName" class="m-2">Název pobočky:</label>
        <input id="branchName" type="text" class="form-control my-2" v-model="data.name">
        <label for="zipCodes" class="m-2">Seznam PSČ (oddělené čárkami):</label>
        <textarea id="zipCodes" class="form-control my-2 textfield_height" v-model="data.zip_codes" />
        <label for="region" class="m-2">Kraj:</label>
        <select id="region" class="form-select my-2" v-model="data.region_id" disabled>
          <option v-for="region in regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </option>
        </select>
      </div>
    </template>
  </Popup>
  <Popup :header="'Založit novou pobočku'"
    :editable="true"
    id="addBranch"
    @save-data="createBranch">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="branchName" class="m-2">Název pobočky:</label>
        <input id="branchName" type="text" class="form-control my-2" v-model="data.name">
        <label for="zipCodes" class="m-2">Seznam PSČ (oddělené čárkami):</label>
        <textarea id="zipCodes" class="form-control my-2 textfield_height" v-model="data.zip_codes" />
        <label for="region" class="m-2">Kraj:</label>
        <select id="region" class="form-select my-2" v-model="data.region_id">
          <option v-for="region in regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </option>
        </select>
      </div>
    </template>
  </Popup>
  <Popup :header="'Zrušit pobočku ' + data.name" :body="'Opravdu si přejete nenávratně smazat pobočku?'"
    id="deleteBranch" :button="'Smazat'" @save-data="deleteBranch"/>
  <LoadingSpinner :csvLoading="loading"/>
</template>


<script>
import Popup from "@/components/Popup.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    Popup,
    LoadingSpinner
  },
  data() {
    return {
      branch_id: null,
      data: {
        name: "",
        zip_codes: "",
        region_id: null
      },
      loading: false
    }
  },
  computed: {
    branches() {
      const branchesAll = this.$store.getters.branches;
      if (this.$store.getters.user.krp_region_id && !this.$store.getters.hasPerm('manage_all_regions')) {
        const branches = branchesAll.filter(branch => branch.parent && branch.parent.id === this.$store.getters.user.krp_region_id);
        return branches;
      }
      return branchesAll;
    },
    regions() {
      const regionsAll = this.$store.getters.regions;
      if (this.$store.getters.user.krp_region_id && !this.$store.getters.hasPerm('manage_all_regions')) {
        const regions = regionsAll.filter(region => region.id === this.$store.getters.user.krp_region_id);
        return regions;
      }
      return regionsAll;
    },
  },
  methods: {
    setBranch(branch) {
      this.branch_id = branch.id;
      this.data.name = branch.name;
      this.data.zip_codes = branch.zip_codes;
      if (branch.parent) {
        this.data.region_id = branch.parent.id;
      }
    },
    resetBranch() {
      this.branch_id = null;
      this.data.name = "";
      this.data.zip_codes = "";
      this.data.region_id = null;
    },
    validateData() {
      this.data.zip_codes = this.data.zip_codes.replace(/\s+/g, ''); // remove whitespace characters
      const regex = /^(\d{5}(,\d{5})*)?$/; // expects 5 digits separated by commas

      if (!this.data.name || !this.data.zip_codes || !this.data.region_id) {
        alert("Vyplňte prosím všechny údaje.");
        return false;
      }
      if (!regex.test(this.data.zip_codes)) {
        alert("Nesprávný formát seznamu PSČ. Musí obsahovat 1 nebo více PSČ oddělených čárkami.");
        return false;
      }
      return true;
    },
    createBranch() {
      if (!this.validateData()) {
        return;
      }
      this.loading = true;
      this.$store.getters.api.post("/branch/create", this.data).then((_response) => {
        this.$store.dispatch("reloadBranches").then(() => {
          this.loading = false;
        });
      });
    },
    updateBranch() {
      if (!this.validateData()) {
        return;
      }
      this.loading = true;
      this.$store.getters.api.put("/branch/" + this.branch_id + "/update", this.data).then((_response) => {
        this.$store.dispatch("reloadBranches").then(() => {
          this.loading = false;
        });
      });
    },
    deleteBranch() {
      this.loading = true;
      this.$store.getters.api.delete("/branch/" + this.branch_id  + "/delete").then((_response) => {
        this.$store.dispatch("reloadBranches").then(() => {
          this.loading = false;
        });
      });
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.textfield_height {
  min-height: 150px;
}

.item {
    div &:nth-of-type(odd) {
        background-color: $background-color;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-grey, 5%);
        }
    }

    &:nth-of-type(even) {
        background-color: $background-grey;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-grey, 5%);
        }
    }
}
</style>