<template>
  <div class="col-12 pt-4">
    <div class="justify-content-between d-flex">
      <h2>Odkazy</h2>
      <button type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addLink"
        @click="resetMessage()"
        v-if="$store.getters.hasPerm('change_person') || $store.getters.user.id == memberId">
        <icon icon="Plus" />
      </button>
    </div>
    <div>
      <p v-if="links.length === 0" class="my-2">Žádné odkazy</p>
      <p v-if="$store.getters.hasPerm('view_person') || $store.getters.user.id == memberId" v-for="(link, index) in links" :key="index" class="my-2">
        <span v-html="link.site.icon"></span>
        <a :href="getUrl(link)" class="px-2 member-link">
          {{ getUrl(link) }}
        </a>
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editLink"
          @click="setMessage(link)"
          v-if="$store.getters.hasPerm('change_person') || $store.getters.user.id == memberId">
          Editovat
        </button>
      </p>
    </div>
  </div>
  <Popup
    :header="'Nový odkaz'"
    :editable="true"
    id="addLink"
    @save-data="postLink"
    v-if="$store.getters.hasPerm('change_person') || $store.getters.user.id == memberId">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="site" class="m-2">Vyberte typ odkazu:</label>
        <select id="site" class="form-select my-2" v-model="site">
          <template v-for="site in sites">
            <option :value="site">{{ site.name }}</option>
          </template>
        </select>
        <label for="link" class="m-2">Vložte odkaz:</label>
        <div class="my-2 d-flex align-items-center">
          <span>{{ site?.url || '' }}&nbsp;</span><input id="link" type="text" class="form-control" v-model="link">
        </div>
      </div>
    </template>
  </Popup>
  <Popup
    :header="'Editovat odkaz'"
    :editable="true"
    id="editLink"
    @save-data="editLink"
    v-if="$store.getters.hasPerm('change_person') || $store.getters.user.id == memberId">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="site" class="m-2">Vyberte typ odkazu:</label>
        <select id="site" class="form-select my-2" v-model="site">
          <template v-for="site in sites">
            <option :value="site">{{ site.name }}</option>
          </template>
        </select>
        <label for="link" class="m-2">Vložte odkaz:</label>
        <div class="my-2 d-flex align-items-center">
          <span>{{ site?.url || '' }}&nbsp;</span><input id="link" type="text" class="form-control" v-model="link">
        </div>
      </div>
    </template>
  </Popup>
</template>


<script>
import Popup from '@/components/Popup.vue';

export default {
  components: {
    Popup
  },
  props: {
    memberId: {
      required: true
    }
  },
  data() {
    return {
      links: [],
      sites: [],
      link: "",
      id: "",
      site: null
    }
  },
  methods: {
    getUrl(link) {
      return link.site.url + link.url;
    },
    setMessage(link) {
      this.link = link.url;
      this.id = link.id;
      this.site = link.site;
    },
    resetMessage() {
      this.link = "";
      this.site = null;
    },
    postLink: function () {
      this.$store.getters.api.post("/member/" + this.memberId + "/social", { url: this.link, site_id: this.site.id }).then(function (response) {
        this.loadData(); // Reload data to display the newly created note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se vložit odkaz. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    editLink: function () {
      this.$store.getters.api.put("/member/" + this.memberId + "/social", { link_id: this.id, url: this.link, site_id: this.site.id }).then(function (response) {
        this.loadData(); // Reload data to display the edited note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    loadData: function () {
      this.$store.getters.api.get("/member/" + this.memberId + "/social").then(function (response) {
        this.links = response.data.items;
      }.bind(this));
      this.$store.getters.api.get("/social").then(function (response) {
        this.sites = response.data.items;
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

h2 {
  font-size: 24px;
}

.btn-outline-secondary:hover {
  .icon {
    stroke: #fff;
    transition: stroke 0.15s ease-in-out;
  }
}

.btn-outline-secondary:active, .btn-outline-secondary:target {
  .icon {
    stroke: #fff;
    transition: stroke 0.15s ease-in-out;
  }
}

.member-link {
  color: #212529;
}
.member-link:hover {
  color: $green-dark;
}
</style>