<template>
    <div class="row">
      <div class="col-6 font-weight-bold py-2">
        <slot name="header">{{ header }}</slot>
      </div>
      <div class="d-flex align-items-center" :class="editClasses">
        <template v-if="editable">
          <slot name="editor">
              <input class="form-control" :type="inputType" v-model="model"/>
          </slot>
        </template>
        <template v-else>
          <slot name="body">{{ body ? body : "-" }}</slot>
        </template>
      </div>
      <div class="col-1 py-2 d-inline-flex" v-if="forSharing && canEdit">
        <slot name="sharing">
          <input class="form-check-input ms-auto" type="checkbox" :disabled="isDisabled" v-model="sharingModel" />
        </slot>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    body: {
      required: false
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    editableCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    forSharing: {
      type: Boolean,
      required: false,
      default: false
    },
    shared: {
      type: Boolean,
      required: false,
      default: false
    },
    sharedAlways: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isDisabled() {
      if (this.editableCheckbox) {
        return this.sharedAlways;
      }
      return true;
    },
    isShared() {
      if (this.sharedAlways) {
        return this.sharedAlways;
      } else {
        return this.shared;
      }
    },
    canEdit: function () {
      return this.$store.getters.user.id == this.$route.params.id
    },
    model: {
      get() {
        return this.body
      },
      set(newValue) {
        this.$emit('body', newValue)
      }
    },
    sharingModel: {
      get() {
        return this.isShared;
      },
      set(newValue) {
        this.$emit('sharing', newValue);
      }
    },
    editClasses() {
      let classString = '';
      if (this.$store.getters.user.id == this.$route.params.id) {
        classString = 'col-5';
      } else {
        classString = 'col-6';
      }
      if (!this.editable) {
        classString += ' py-2';
      }
      return classString;
    }
  }
}
</script>


<style scoped lang="scss"></style>