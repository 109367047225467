<template>
  <div class="pb-4">
    <DocumentDetailRow header="ID"
      :body="document.id"
      v-if="document.id != null"
    />
    <DocumentDetailRow header="Soubory *"
      :editable="editable"
      :body="document.document"
      v-if="document.id == null">
      <template v-slot:editor>
        <input type="file" accept=".pdf, .jpg, .png, .gif" multiple @change="changeDoc">
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Typ dokladu *"
      :editable="$store.getters.hasPerm('select_details_finances') && editable"
      :body="document.document_type?.name || '-'">
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedDocType">
          <option v-for="doctype in document_types" :key="doctype.id" :value="doctype.id">
            {{ doctype.name }}
          </option>
        </select>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Vytvořil">
      <template v-if="document.created_by && document.created_at" v-slot:body>
        <router-link class="redirect_link" :to="{ name: 'member-detail', params: { id: document.created_by.id } }">
          {{ document.created_by.name }}
        </router-link>
      </template>
      <template v-else v-slot:body>
        {{ document.created_by?.name || 'automatický import' }}
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Organizace *"
      :body="'Svobodní'"
    />
    <DocumentDetailRow header="Org. složka *"
      :editable="editable"
      :body="document.region?.name || '-'">
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedRegion">
          <option v-for="reg in regions" :key="reg.id" :value="reg.id">
            {{ reg.name }}
          </option>
        </select>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Kapitola"
      :editable="editable"
      :body="document.chapter?.name || '-'">
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedChapter">
          <option v-for="chapter in chapters" :key="chapter.id" :value="chapter.id">
            {{ chapter.name }}
          </option>
        </select>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Podkapitola"
      :editable="editable"
      :body="document.subchapter?.name || '-'">
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedSubchapter">
          <option v-for="subchapter in subchapters" :key="subchapter.id" :value="subchapter.id">
            {{ subchapter.name }}
          </option>
        </select>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Obj. č."
      :editable="editable"
      :body="document.document_number"
      @body="(newValue) => handleBodyChange('data', 'document_number', newValue)"
    />
    <DocumentDetailRow header="Popis plnění *"
      :editable="editable"
      :body="document.description"
      @body="(newValue) => handleBodyChange('data', 'description', newValue)"
    />
    <DocumentDetailRow header="Částka *"
      :editable="editable"
      :body="document.amount"
      :inputType="'number'"
      @body="(newValue) => handleBodyChange('data', 'amount', newValue)"
    />
    <DocumentDetailRow :header="document.document_type && document.document_type.financial_type === 'V' ? 'Var. symbol *' : 'Var. symbol'"
      :editable="editable"
      :body="document.vs"
      @body="(newValue) => handleBodyChange('data', 'vs', newValue)"
    />
    <DocumentDetailRow header="Konst. symbol"
      :editable="editable"
      :body="document.ks"
      @body="(newValue) => handleBodyChange('data', 'ks', newValue)"
    />
    <DocumentDetailRow header="Spec. symbol"
      :editable="editable"
      :body="document.ss"
      @body="(newValue) => handleBodyChange('data', 'ss', newValue)"
    />
    <DocumentDetailRow header="Číslo účtu *"
      :editable="editable"
      :body="bankAccount(document.pay_to)">
      <template v-slot:editor>
        {{ bankAccount(document.pay_to) }}
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editAccount">
          Editovat
        </button>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Zaplatit z účtu"
      :editable="$store.getters.hasPerm('select_details_finances') && editable"
      :body="bankAccount(document.pay_from)">
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedAccount">
          <option v-for="account in this.bankAccounts" :key="account.id" :value="account.id">
            {{ (account.name ? account.name + ' ' : '') + '(' + bankAccount(account) + ')' }}
          </option>
        </select>
      </template>
    </DocumentDetailRow>
    <DocumentDetailRow header="Platba vrácena"
      :body="document.returned_at ? document.returned_at.split(' ')[0] + ` pod ID ${document.returned_document}` : '-'"
    />
    <DocumentDetailRow header="Platba provedena jiným dokladem"
      :editable="editable"
      :body="document.already_paid_document"
      @body="(newValue) => handleBodyChange('data', 'already_paid_document', newValue)"
      :inputType="'number'"
    >
      <template v-slot:body>
        <template v-if="document.already_paid_document">
            <router-link :to="{ name: 'finance-detail', params: { id: document.already_paid_document } }" class="redirect_link">{{ document.already_paid_document }}</router-link>
        </template>
        <template v-else>
          <span>-</span>
        </template>
      </template>
    </DocumentDetailRow>
  </div>
  <Popup
    :header="'Zaplatit na účet'"
    :editable="true"
    id="editAccount"
    @save-data="setAccount">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="name" class="m-2">Název účtu:</label>
        <input id="name" type="text" class="form-control my-2" v-model="account.name">
        <label for="account_prefix" class="m-2">Předčíslí:</label>
        <input id="account_prefix" type="text" class="form-control my-2" v-model="account.account_prefix">
        <label for="account_number" class="m-2">Číslo účtu:</label>
        <input id="account_number" type="text" class="form-control my-2" v-model="account.account_number">
        <label for="bank_number" class="m-2">Číslo banky:</label>
        <input id="bank_number" type="text" class="form-control my-2" v-model="account.bank_number">
        <label for="iban" class="m-2">IBAN:</label>
        <input id="iban" type="text" class="form-control my-2" v-model="account.iban">
        <label for="bic" class="m-2">SWIFT/BIC:</label>
        <input id="bic" type="text" class="form-control my-2" v-model="account.bic">
      </div>
      <div v-if="!((account.account_number !== '' && account.bank_number !== '') || account.iban !== '')" class="mb-3">
        <p class="m-2">Vyplňte alespoň číslo účtu a banky, nebo IBAN.</p>
      </div>
    </template>
  </Popup>
</template>


<script>
import DocumentDetailRow from '@/components/DocumentDetailRow.vue';
import Popup from '@/components/Popup.vue';

export default {
  components: {
    DocumentDetailRow,
    Popup
  },
  emits: ['update-document', 'file-changed'],
  props: {
    document: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    bankAccounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      account: {
        account_number: '',
        account_prefix: '',
        bank_number: '',
        bic: '',
        iban: '',
        name: '',
      },
      documentFile: null,
    }
  },
  methods: {
    handleBodyChange(where, property, newValue) {
      this.$emit('update-document', { where, property, newValue });
    },
    bodyName(body) {
      if (!body) {
        return '-';
      }
      if (body.organization) {
        return body.organization.name === 'Republikové orgány' ? body.name : `${body.acronym} - ${body.organization.name}`;
      } else {
        return body.name
      }
    },
    bankAccount(account) {
      let accountString = "";
      if (account && account.account_prefix) {
        accountString += account.account_prefix + "-";
      }
      if (account && account.account_number && account.bank_number) {
        accountString += account.account_number + " / " + account.bank_number;
      } else if (account && account.iban) {
        accountString = account.iban;
        if (account.bic) {
          accountString += ", " + account.bic;
        }
      }
      return accountString;
    },
    setAccount() {
      if ((this.account.account_number && this.account.bank_number) || this.account.iban) {
        this.handleBodyChange('pay_to', 'account_number', this.account.account_number);
        this.handleBodyChange('pay_to', 'account_prefix', this.account.account_prefix);
        this.handleBodyChange('pay_to', 'bank_number', this.account.bank_number);
        this.handleBodyChange('pay_to', 'bic', this.account.bic);
        this.handleBodyChange('pay_to', 'iban', this.account.iban);
        this.handleBodyChange('pay_to', 'name', this.account.name);
      } else {
        this.handleBodyChange('pay_to', 'account_number', '');
        this.handleBodyChange('pay_to', 'account_prefix', '');
        this.handleBodyChange('pay_to', 'bank_number', '');
        this.handleBodyChange('pay_to', 'bic', '');
        this.handleBodyChange('pay_to', 'iban', '');
        this.handleBodyChange('pay_to', 'name', '');
      }
      this.account.account_number = '';
      this.account.account_prefix = '';
      this.account.bank_number = '';
      this.account.bic = '';
      this.account.iban = '';
      this.account.name = '';
    },
    changeDoc(event) {
      this.documentFiles = event.target.files;
      this.$emit('file-changed', this.documentFiles);
    },
  },
  computed: {
    document_types() {
      const typesAll = this.$store.getters.docTypes;
      return typesAll;
    },
    regions() {
      const countries = this.$store.getters.countries;
      const regions = this.$store.getters.regions;
      const orgAll = countries.concat(regions);
      return orgAll;
    },
    chapters() {
      const allChapters = this.$store.getters.chapters;
      const chapters = [];
      allChapters.forEach((chapter) => {
        if (!chapter.parent && (Object.keys(this.document.document_type).length === 0 || chapter.financial_type === this.document.document_type.financial_type)) {
          chapters.push(chapter);
        }
      });
      return chapters;
    },
    subchapters() {
      const allChapters = this.$store.getters.chapters;
      const subchapters = [];
      if (this.selectedChapter === null) {
        allChapters.forEach((chapter) => {
          if (chapter.parent) {
            subchapters.push(chapter);
          }
        });
      } else {
        allChapters.forEach((chapter) => {
          if (chapter.parent && chapter.parent.id === this.selectedChapter) {
            subchapters.push(chapter);
          }
        });
      }
      return subchapters;
    },
    selectedDocType: {
      get() {
        return this.document.document_type.id;
      },
      // uses emit to change props in parent component
      set(newValue) {
        if (newValue === null) {
          this.handleBodyChange('document_type', 'id', null);
          this.handleBodyChange('document_type', 'name', '');
          return;
        }
        const newDocType = this.document_types.find(docType => docType.id === newValue);
        for (let key in newDocType) {
          this.handleBodyChange('document_type', key, newDocType[key]);
        }
      }
    },
    selectedRegion: {
      get() {
        return this.document.region.id;
      },
      // uses emit to change props in parent component
      set(newValue) {
        if (newValue === null) {
          this.handleBodyChange('region', 'id', null);
          this.handleBodyChange('region', 'name', '');
          return;
        }
        const newRegion = this.regions.find(region => region.id === newValue);
        for (let key in newRegion) {
          this.handleBodyChange('region', key, newRegion[key]);
        }
      }
    },
    selectedChapter: {
      get() {
        return this.document.chapter.id;
      },
      // uses emit to change props in parent component
      set(newValue) {
        if (newValue === null) {
          this.handleBodyChange('chapter', 'id', null);
          this.handleBodyChange('chapter', 'name', '');
          this.handleBodyChange('subchapter', 'id', null);
          this.handleBodyChange('subchapter', 'name', '');
          return;
        }
        const newChapter = this.chapters.find(chapter => chapter.id === newValue);
        for (let key in newChapter) {
          this.handleBodyChange('chapter', key, newChapter[key]);
        }
        this.handleBodyChange('subchapter', 'id', null);
        this.handleBodyChange('subchapter', 'name', '');
      }
    },
    selectedSubchapter: {
      get() {
        return this.document.subchapter.id;
      },
      // uses emit to change props in parent component
      set(newValue) {
        if (newValue === null) {
          this.handleBodyChange('subchapter', 'id', null);
          this.handleBodyChange('subchapter', 'name', '');
          return;
        }
        const newSubchapter = this.subchapters.find(subchapter => subchapter.id === newValue);
        for (let key in newSubchapter) {
          this.handleBodyChange('subchapter', key, newSubchapter[key]);
        }
      }
    },
    selectedAccount: {
      get() {
        return this.document.pay_from.id;
      },
      // uses emit to change props in parent component
      set(newValue) {
        if (newValue === null) {
          this.handleBodyChange('pay_from', 'id', null);
          this.handleBodyChange('pay_from', 'name', '');
          return;
        }
        const newAccount = this.bankAccounts.find(account => account.id === newValue);
        for (let key in newAccount) {
          this.handleBodyChange('pay_from', key, newAccount[key]);
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
  .row {
    border-bottom: 1px solid #ccc;
  }

  /* hide arrows for number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.redirect_link {
  color: #212529 !important;
  text-decoration: none !important;
}
.redirect_link:hover {
  color: $green-dark !important;
}
</style>