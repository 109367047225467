<template>
  <nav class="navbar navbar-expand-xl navbar-light bg-transparent px-4">
    <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
      <img src="https://www.svobodni.cz/wp-content/themes/Svobodni/images/logo.png">
    </router-link>
    <h1 class="navbar-title mb-0" v-if="$store.getters.isLoggedIn">
      <template v-for="(item, index) in pageTitle.title" :key="index">
        <router-link
          v-if="pageTitle.before[index] && pageTitle.beforePermission[index] && $store.getters.hasPerm(pageTitle.beforePermission[index])"
          :to="{ name: pageTitle.before[index] }">
          {{ item }}
        </router-link>
        <template v-else>{{ item }}</template>
        <template v-if="index !== pageTitle.title.length - 1">&nbsp;/&nbsp;</template>
      </template>
    </h1>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto" v-if="$store.getters.isLoggedIn">
        <li class="nav-item mx-2">
          <a class="nav-link" href="https://vs.svobodni.cz" target="_blank">Volební systém</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link" href="https://files.svobodni.cz" target="_blank">Files</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link" href="https://wiki.svobodni.cz" target="_blank">Wiki</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link" href="https://forum.svobodni.cz" target="_blank">Fórum</a>
        </li>
        <li class="nav-item mx-2" v-if="$store.getters.hasPerm('view_finances')">
          <router-link class="nav-link" :to="{ name: 'finances' }">Platby</router-link>
        </li>
        <li class="nav-item mx-2" v-if="$store.getters.hasPerm('view_finances')">
          <router-link class="nav-link" :to="{ name: 'financesBudgets' }">Finance</router-link>
        </li>
        <li class="nav-item mx-2" v-if="$store.getters.hasPerm('view_person')">
          <router-link class="nav-link" :to="{ name: 'members' }">Registr členů</router-link>
        </li>
        <li class="nav-item mx-2" v-if="$store.getters.hasFunction('Předseda strany')
          || $store.getters.hasFunction('1. Místopředseda')
          || $store.getters.hasFunction('Místopředseda')
          || $store.getters.hasFunction('Krajský Předseda')
          || $store.getters.hasFunction('Krajský Místopředseda')
          || $store.getters.hasFunction('Kancelář')">
          <router-link class="nav-link" :to="{ name: 'settings' }">Nastavení</router-link>
        </li>
        <li class="nav-item mx-2">
          <router-link class="nav-link" :to="{ name: 'member-detail', params: { id: $store.getters.user.id } }">{{
            $store.getters.user.name }}</router-link>
        </li>
        <li class="nav-item mx-2">
          <router-link class="nav-link" :to="{ name: 'logout' }">Odhlásit</router-link>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto" v-else>
        <li class="nav-item mx-2">
          <router-link class="nav-link" :to="{ name: 'login' }">Přihlásit</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss">
@import "@/scss/abstracts/_variables.scss";

.navbar {
  background-color: transparent;

  .navbar-brand,
  .navbar-title {
    display: flex;
    align-items: center;
    font-size: 26px;

    img {
      width: 235px;
      max-height: 50px;
      margin-right: 20px;
    }
  }

  .navbar-title {
    margin-top: 7px;
  }

  .nav-item, .navbar-title {
    a {
      color: #111;
      text-decoration: none;

      &:hover {
        color: $green-dark;
      }
    }
  }
}</style>
