// common configuration
var config = {
    api: {
        baseUrl: "https://registr.svobodni.cz"
    },
    afterLoginRoute: "dashboard",
    vueAppUrl: "https://registr.svobodni.cz/app",
    captchaPublicToken: "6LeqZT8mAAAAAC9M62ouLzaPUiSHT8EMzZfMIrFB",
    recommendedPayment: 2024,
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
    config["vueAppUrl"] = "https://registr.svobodni.cz/app";
    config["api"]["baseUrl"] = "https://registr.svobodni.cz";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "staging") {
    config["vueAppUrl"] = "https://staging.registr.svobodni.cz/app";
    config["api"]["baseUrl"] = "https://staging.registr.svobodni.cz";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10000";
    config["api"]["baseUrl"] = "http://localhost:10001";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dig_test") {
    config["vueAppUrl"] = "https://svobodni.digitalwish.eu";
    config["api"]["baseUrl"] = "https://svobodni.digitalwish.eu/reg";
    // config["api"]["baseUrl"] = "https://reg.svobodni.zvara.cz";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_michal") {
    config["vueAppUrl"] = "http://localhost:35001";
    config["api"]["baseUrl"] = "http://localhost:35002";
    // config["api"]["baseUrl"] = "https://reg.svobodni.zvara.cz";
}

export const CONFIG = config;