<template>
  <input class="form-check-input ms-auto" type="checkbox" />
</template>


<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>


<style lang="scss" scoped>

</style>