<template>
  <div class="row p-4">
    <div class="col-12 col-xl-6 px-4">
      <MemberHeader ref="header" :member="data" :memberId="memberId" :editable="editable" @edit-data="editData" @reload-data="reloadAll" />
      <MemberDetail :member="data" :editable="editable" @update-member="handleMemberUpdate" />
      <MemberSocial ref="social" :memberId="memberId" v-if="$store.getters.hasPerm('view_person') || $store.getters.user.id == memberId"/>
      <MemberAdditionalInfo :memberId="memberId" v-if="$store.getters.hasPerm('view_additionalinfoperson')"/>
    </div>
    <div class="col-12 col-xl-6 px-4">
      <Membership ref="functions" :member="data" :memberId="memberId" :isActiveMember="data.is_member" :domesticRegion="data.domestic_region" @reload-data="reloadAll" v-if="$store.getters.hasPerm('view_personfunction') || $store.getters.user.id == memberId"/>
      <div class="row pt-5">
        <MemberEvents ref="events" :memberId="memberId" v-if="$store.getters.hasPerm('view_log')"/>
        <MemberNotes ref="notes" :memberId="memberId" v-if="$store.getters.hasPerm('view_note')"/>
      </div>
    </div>
  </div>
</template>


<script>
import MemberHeader from "@/components/MemberHeader.vue";
import Membership from "@/components/Membership.vue";
import MemberDetail from "@/components/MemberDetail.vue";
import MemberEvents from "@/components/MemberEvents.vue";
import MemberNotes from "@/components/MemberNotes.vue";
import MemberSocial from "@/components/MemberSocial.vue";
import MemberAdditionalInfo from "@/components/MemberAdditionalInfo.vue";

export default {
  components: {
    MemberHeader,
    Membership,
    MemberDetail,
    MemberEvents,
    MemberNotes,
    MemberSocial,
    MemberAdditionalInfo
  },
  data() {
    return {
      memberId: null,
      editable: false,
      data: {
        user: {},
        domestic_address: {},
        postal_address: {},
        domestic_region: {},
        domestic_branch: {},
        guest_region: {},
        guest_branch: {},
        sharing: {}
      },
    }
  },
  watch: {
    "$route.params.id": {
      handler: function (val) {
        if (val) {
          this.memberId = this.$route.params.id;
          this.loadData().then(() => {
            if (this.$refs.functions) {
              this.$refs.functions.loadData();
            }
            if (this.$refs.events) {
              this.$refs.events.loadData();
            }
            if (this.$refs.notes) {
              this.$refs.notes.loadData();
            }
            if (this.$refs.social) {
              this.$refs.social.loadData();
            }
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleMemberUpdate({ where, property, newValue }) {
      switch (where) {
        case 'user':
          this.data.user[property] = newValue;
          break;
        case 'sharing':
          this.data.sharing[property] = newValue;
          break;
        case 'domestic_address':
          this.data.domestic_address[property] = newValue;
          break;
        case 'postal_address':
          this.data.postal_address[property] = newValue;
          break;
        default:
          this.data[property] = newValue;
      }
    },
    editData(save) {
      if (this.editable && save === true) {
        // save changes to member data
        if (this.data.user.email == "") {
          alert("Musíte zadat emailovou adresu.");
          return;
        }
        this.$store.getters.api.put("/member/" + this.data.id, this.data)
          .then(function () {
            this.editable = false;
            this.reloadAll();
          }.bind(this))
          .catch(function (error) {
            alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + JSON.stringify(error.response.data));
          });
      } else if (this.editable) {
        this.editable = false;
        this.reloadAll();
      } else {
        this.editable = true;
      }
    },
    reloadAll: function () {
      this.loadData();
      if (this.$refs.functions) {
        this.$refs.functions.loadData();
      }
      if (this.$refs.events) {
        this.$refs.events.loadData();
      }
    },
    loadData: function () {
      return this.$store.getters.api.get("/member/" + this.memberId).then(function (response) {
        this.data = response.data.items;
        if (this.data.avatar) {
          this.$refs.header.downloadPhoto();
        }
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">

</style>
