<template>
    <div class="d-flex flex-wrap flex-column mx-2">
        <span class="filter_column_heading">Vybrané sloupečky</span>
        <div class="d-flex flex-wrap flex-row">
            <div v-for="(column, index) in settings.columns" :index="index" :v-key="index" class="m-2">
                <input type="checkbox" class="form-check-input" v-on:change="changeColumn(column)"
                    :checked="isSelected(column)" :disabled="isDisabled(column)"/><span class="mx-2" :class="{'disabled_text': isDisabled(column)}">{{ column.userFriendly }}</span>
            </div>
        </div>
        <span v-if="isSomeDisabled" class="font-weight-bold">Některé sloupečky není možné zvolit. Proto abyste si je mohli zvolit, musíte odebrat již zvolené.</span>
    </div>
</template>

<script>
export default {
    props: {
        settings: {
            type: Object,
            required: true
        },
        selectedColumns: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            allColumns: this.settings.columns,
            isSomeDisabled: true
        }
    },
    watch: {
        selectedColumns: {
            handler: function () {
                this.isSomeDisabled = this.allColumns.some(col => this.isDisabled(col));
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        isSelected: function () {
            return column => this.selectedColumns.includes(column)
        },
        isDisabled: function () {
            return column => {
                if (this.isSelected(column)) {
                    return false;
                }

                const sum = this.allColumns.filter((value) =>{
                    return this.selectedColumns.includes(value)
                }).reduce((partialSum, column) => partialSum + column.col, 0);

                return sum + column.col > 12;
            }
        }
    },
    methods: {
        changeColumn: function (column) {
            const changedColumns = this.selectedColumns;
            if (this.isSelected(column)) {
                const index = changedColumns.indexOf(column);
                changedColumns.splice(index, 1);
            }
            else {
                changedColumns.push(column);
            }
            const filteredColumns = this.allColumns.filter((value) =>{
                return changedColumns.includes(value)
            });
            this.$emit("changeColumns", filteredColumns);
        },
    }
}
</script>

<style lang="scss">
.disabled_text {
    color: grey;
}

.filter_column_heading {
    font-weight: 500;
    font-size: 18px;
}

</style>