<template>
  <div class="item">
    <div class="checkbox-size d-flex align-items-center">
      <input type="checkbox" class="form-check-input" v-model="model"/>
    </div>
    <div class="row data-column-width d-flex align-items-stretch">
      <!-- <div v-for="(column, index) in columns" :index="index" :v-key="index" :class="getClass(column)"
      @click="this.settings.row.isClickable(this.item) && !column.cellComponent ? this.settings.row.redirect(this.item) : null">
        <template v-if="column.cellComponent">
          <component :is="column.cellComponent" :item="item" />
        </template>
        <template v-else-if="column.cellFunction">
          {{ column.cellFunction(item) || column.default }}
        </template>
        <template v-else>
          {{ item[column.name] || column.default }}
        </template>
      </div> -->
      <template v-for="(column, index) in columns" :key="index">
        <router-link
          v-if="this.settings.row.isClickable(this.item) && !column.cellComponent"
          :to="{ name: settings.row.name, params: { id: item.id } }" :class="getClass(column)">
          <template v-if="column.cellComponent">
            <component :is="column.cellComponent" :item="item" />
          </template>
          <template v-else-if="column.cellFunction">
            {{ column.cellFunction(item) || column.default }}
          </template>
          <template v-else>
            {{ item[column.name] || column.default }}
          </template>
        </router-link>
        <div v-else :class="getClass(column)">
          <template v-if="column.cellComponent">
            <component :is="column.cellComponent" :item="item" />
          </template>
          <template v-else-if="column.cellFunction">
            {{ column.cellFunction(item) || column.default }}
          </template>
          <template v-else>
            {{ item[column.name] || column.default }}
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.selected
      },
      set() {
        this.$emit('changeSelected', this.item.id, !this.selected)
      }
    }
  },
  methods: {
    getClass(column) {
      let classString = '';
      if (column.classFunction) {
        classString = column.classFunction(this.item) + ' ';
      }
      return this.settings.row.isClickable(this.item) && !column.cellComponent
        ? classString + 'py-2 d-flex align-items-center cursor_pointer col-' + column.col
        : classString + 'py-2 d-flex align-items-center col-' + column.col;
    },
  },
}
</script>

<style lang="scss">
a {
  color: #212529 !important;
  text-decoration: none !important;
}
.green {
  color: rgb(0, 138, 0) !important;
}
.red {
  color: rgb(209, 0, 0) !important;
}
</style>