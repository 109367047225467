import { createStore } from 'vuex'

import axios from "axios"
import jwt_decode from "jwt-decode"
import { CONFIG } from "@/config.js"
import router from "@/router.js"

const api = axios.create({
  baseURL: CONFIG.api.baseUrl,
  withCredentials: true
});

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && [401, 403].some(code => code == error.response.status)) {
      router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
)
// Create a new store instance.
const store = createStore({
  state () {
    return {
      api: api,
      token: null,
      loaded: false,
      loaded_list: [
        "user",
        "bodies",
        "country",
        "region",
        "branch",
        "chapters",
        "finance_types",
        "user_settings",
        "functions",
      ],
      perms: [],
      user_functions: [],
      user: {
        name: 0,
        id: 0,
        user: {},
        domestic_address: {},
        postal_address: {},
        domestic_region: {},
        domestic_branch: {},
        guest_region: {},
        guest_branch: {},
        sharing: {},
      },
      countries: [],
      regions: [],
      branches: [],
      bodies: [],
      chapters: [],
      docTypes: [],
      docStates: [
        {
          id: "error",
          name: "Chyba platby",
          document_type: "V",
        },
        {
          id: "to_pay",
          name: "Probíhá platba",
          document_type: "V",
        },
        {
          id: "paid",
          name: "Zaplacené",
          document_type: "V",
        },
        {
          id: "unpaid",
          name: "Nezaplacené",
          document_type: "V",
        },
        {
          id: "approved",
          name: "Schválené",
          document_type: "",
        },
        {
          id: "unapproved",
          name: "Neschválené",
          document_type: "",
        },
        {
          id: "rejected",
          name: "Zamítnuté",
          document_type: "",
        },
        {
          id: "without_contract",
          name: "Bez smlouvy",
          document_type: "P",
        },
        {
          id: "with_contract",
          name: "Se smlouvou",
          document_type: "P",
        },
        {
          id: "not_assigned",
          name: "Nepřiřazené",
          document_type: "",
        },
        {
          id: "not_extracted",
          name: "Nevytěžené",
          document_type: "V",
        },
        {
          id: "waiting_fin",
          name: "Čeká na schválení fin. odborem",
          document_type: "V",
        },
        {
          id: "over1000",
          name: "Nad 1000 Kč",
          document_type: "",
        },
        {
          id: "over10000",
          name: "Nad 10 000 Kč",
          document_type: "",
        },
        {
          id: "over50000",
          name: "Nad 50 000 Kč",
          document_type: "",
        },
        {
          id: "over100000",
          name: "Nad 100 000 Kč",
          document_type: "",
        },
      ],
      memberships: [
        {
          id: null,
          name: "Zaplaceno i nezaplaceno"
        },
        {
          id: "paid",
          name: "Zaplaceno",
        },
        {
          id: "unpaid",
          name: "Nezaplaceno",
        },
      ],
      positions: [],
      settings: [],
      filtersRegister: {
        search: "",
        member_types: [],
        domestic_regions: [],
        domestic_branches: [],
        member_status: null,
        columns: [],
        per_page: 50,
        current_page: 1,
        sort_by: "id",
        sort_order: "D",
        guest: false,
        guest_region_id: null,
        historical: false,
        function_from: null,
        function_till: null,
        paid_from: null,
        paid_till: null,
        birth_from: null,
        birth_till: null,
        body: null,
      },
      filtersFinance: {
        search: "",
        account: [],
        document_type: [],
        chapter: [],
        region: [],
        document_state: [],
        subchapter: [],
        year: [],
        approved_by: [],
        columns: [],
        per_page: 50,
        current_page: 1,
        sort_by: "created_at",
        sort_order: "D",
        paired: "A",
        income: "A",
      },
    }
  },
  getters: {
    api: function (state) {
      return state.api;
    },
    isLoggedIn: function (state) {
      return !!state.token;
    },
    token: function(state){
      return state.token;
    },
    user: function (state) {
      return state.user;
    },
    countries: function (state) {
      return state.countries;
    },
    regions: function (state) {
      return state.regions;
    },
    branches: function (state) {
      return state.branches;
    },
    bodies: function (state) {
      return state.bodies;
    },
    chapters: function (state) {
      return state.chapters;
    },
    docTypes: function (state) {
      return state.docTypes;
    },
    docStates: function (state) {
      return state.docStates;
    },
    memberships: function (state) {
      return state.memberships;
    },
    positions: function (state) {
      return state.positions;
    },
    settings: function (state) {
      return state.settings;
    },
    loaded: function (state) {
      return state.loaded;
    },
    hasPerm: (state) => (perm_name) => {
      return state.perms.includes(perm_name)
    },
    hasFunction: (state) => (function_name) => {
      return state.user_functions.includes(function_name)
    },
    filtersRegister: function (state) {
      return state.filtersRegister;
    },
    filtersFinance: function (state) {
      return state.filtersFinance;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      state.api.defaults.headers.common['Authorization'] =  `Bearer ${token}`;
      var decoded = jwt_decode(token);
      var userId = decoded.user_id;

      state.api.get("/auth/user").then(response => {
        var data = response.data.items;
        state.perms = data.perms;
        delete data.perms;
        state.user_functions = data.member_type;
        delete data.member_type;
        state.user = data;

        this.commit('removeLoaded', "user");
      });

      state.api.get("/bodies").then(response => {
        state.bodies = response.data.items.sort((a, b) => (a.id - b.id)); // Sort bodies by id
        this.commit('removeLoaded', "bodies");
      });

      state.api.get("/organizations?type=Country").then(response => {
        state.countries = response.data.items.sort((a, b) => a.name.localeCompare(b.name)); // Sort countries by name
        this.commit('removeLoaded', "country");
      });

      state.api.get("/organizations?type=Region").then(response => {
        state.regions = response.data.items.sort((a, b) => a.name.localeCompare(b.name)); // Sort regions by name
        this.commit('removeLoaded', "region");
      });

      state.api.get("/organizations?type=Branch").then(response => {
        state.branches = response.data.items.sort((a, b) => a.name.localeCompare(b.name)); // Sort branches by name
        this.commit('removeLoaded', "branch");
      });

      state.api.get("/chapters").then(response => {
        state.chapters = response.data.items.sort((a, b) => a.name.localeCompare(b.name)); // Sort chapters by name
        this.commit('removeLoaded', "chapters");
      });

      state.api.get("/finance/types").then(response => {
        state.docTypes = response.data.items.sort((a, b) => a.name.localeCompare(b.name)); // Sort types by name
        this.commit('removeLoaded', "finance_types");
      });

      state.api.get("/user/" + userId + "/settings").then(response => {
        state.settings = response.data.items;
        this.commit('removeLoaded', "user_settings");
      });

      state.api.get("/functions").then(response => {
        state.positions = response.data.items.sort((a, b) => a.czech_name.localeCompare(b.czech_name)); // Sort functions by name;
        this.commit('removeLoaded', "functions");
      });

      // set loaded for router
    },
    removeToken(state) {
      state.token = null;
    },
    reloadUser(state) {
      state.api.get("/auth/user").then(response => {
        var data = response.data.items;
        state.perms = data.perms;
        delete data.perms;
        state.user = data;
      });
    },
    reloadSettings(state) {
      state.api.get("/user/" + state.user.user.id + "/settings").then(response => {
        state.settings = response.data.items;
      });
    },
    setFiltersRegister(state, { key, value }) {
      if (state.filtersRegister.hasOwnProperty(key)) {
        state.filtersRegister[key] = value;
      }
    },
    setFiltersFinance(state, { key, value }) {
      if (state.filtersFinance.hasOwnProperty(key)) {
        state.filtersFinance[key] = value;
      }
    },
    resetFiltersRegister(state) {
      state.filtersRegister = {
        search: "",
        member_types: [],
        domestic_regions: [],
        domestic_branches: [],
        member_status: null,
        columns: [],
        per_page: 50,
        current_page: 1,
        sort_by: "id",
        sort_order: "D",
        guest: false,
        guest_region_id: null,
        historical: false,
        function_from: null,
        function_till: null,
        paid_from: null,
        paid_till: null,
        birth_from: null,
        birth_till: null,
        body: null,
      };
    },
    resetFiltersFinance(state) {
      state.filtersFinance = {
        search: "",
        account: [],
        document_type: [],
        chapter: [],
        region: [],
        document_state: [],
        subchapter: [],
        year: [],
        approved_by: [],
        columns: [],
        per_page: 50,
        current_page: 1,
        sort_by: "created_at",
        sort_order: "D",
        paired: "A",
        income: "A",
      };
    },
    removeLoaded(state, name) {
      const index = state.loaded_list.indexOf(name);
        if (index > -1) {
          state.loaded_list.splice(index, 1);
        }
        if (state.loaded_list.length === 0) {
          state.loaded=true;
        }
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },
  },
  actions: {
    async reloadBranches({ commit, state }) {
      state.api.get("/organizations?type=Branch").then(response => {
        const branches = response.data.items.sort((a, b) => a.name.localeCompare(b.name));
        commit('SET_BRANCHES', branches);
      });
    },
  }
})

export default store;