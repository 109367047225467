<template>
  <div class="col-12">
    <div class="justify-content-between d-flex">
      <h2>Poznámky</h2>
      <button type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addNote"
        @click="resetMessage()"
        v-if="$store.getters.hasPerm('add_notefinances')">
        <icon icon="Plus" />
      </button>
    </div>
    <ul>
      <li v-if="notes.length === 0" class="my-2">Žádné poznámky</li>
      <li v-if="$store.getters.hasPerm('view_notefinances')" v-for="(note, index) in notes" :key="index" class="my-2" :class="{ 'pinned': note.pinned }">
        <strong>
          {{ note.modified_at }}
        </strong>:
        {{ note.message }}
        {{ note.modified_at !== note.created_at ? `(poznámka z ${note.created_at}, editováno)` : '' }}
        <br />
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editNote"
          @click="setMessage(note)"
          v-if="$store.getters.hasPerm('change_notefinances')">
          Editovat
        </button>&nbsp;
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          @click="pin(note)"
          v-if="$store.getters.hasPerm('change_notefinances')">
          <icon v-if="!note.pinned" icon="Pin" />
          <icon v-else-if="note.pinned" icon="PinOff" />
        </button>
      </li>
    </ul>
  </div>
  <Popup ref="newNote"
    :header="'Nová poznámka'"
    :editable="true"
    id="addNote"
    @save-data="postNote"
    v-if="$store.getters.hasPerm('add_notefinances')"
  />
  <Popup ref="existingNote"
    :header="'Editovat poznámku'"
    :body="message"
    :editable="true"
    id="editNote"
    @save-data="editNote"
    v-if="$store.getters.hasPerm('change_notefinances')"
  />
</template>


<script>
import Popup from '@/components/Popup.vue';

export default {
  emits: ['reload-data'],
  components: {
    Popup
  },
  props: {
    documentId: {
      required: true
    }
  },
  data() {
    return {
      notes: [],
      message: "",
      id: "",
      pinned: false
    }
  },
  created: function () {
    this.loadData();
  },
  methods: {
    setMessage(note) {
      this.$refs.existingNote.setInput(note.message)
      this.message = note.message
      this.id = note.id
      this.pinned = note.pinned
    },
    resetMessage() {
      this.$refs.newNote.setInput("")
    },
    postNote: function (input) {
      const documentId = this.$route.params.id;

      this.$store.getters.api.post("/finance/" + documentId + "/notes", { message: input }).then(function (response) {
        this.loadData(); // Reload data to display the newly created note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se vložit poznámku. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    editNote: function (input) {
      const documentId = this.$route.params.id;

      this.$store.getters.api.put("/finance/" + documentId + "/notes", { message: input, id: this.id, pinned: this.pinned }).then(function (response) {
        this.loadData(); // Reload data to display the edited note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    pin: function (note) {
      if (note.pinned) {
        this.pinned = false;
      } else {
        this.pinned = true;
      }
      this.id = note.id;
      this.editNote(note.message);
    },
    loadData: function () {
      this.$store.getters.api.get("/finance/" + this.documentId + "/notes").then(function (response) {
        this.notes = response.data.items;
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

h2 {
  font-size: 24px;
}

.pinned {
  background-color: $background-green;
  border: 2px solid $yellow-light;
  padding: 0.7rem;
  border-radius: 0.5rem;
}

.btn-outline-secondary:hover {
  .icon {
    stroke: #fff;
    transition: stroke 0.15s ease-in-out;
  }
}

.btn-outline-secondary:active, .btn-outline-secondary:target {
  .icon {
    stroke: #fff;
    transition: stroke 0.15s ease-in-out;
  }
}
</style>