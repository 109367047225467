<template>
  <div class="card text-center bg-info">
    <div class="card-body">
      <h4 class="card-title">Podpora</h4>      
      <icon icon="QuestionMark" />
      <p class="card-text">
        Není vám něco jasné? Kontaktujte <a href="mailto:kancelar@svobodni.cz">kancelar@svobodni.cz</a>.
      </p>
    </div>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.card {
  margin: 20px auto;
}

.bg-info {
  background-color: $background-green !important;  
  border-color: $green-light;
}
</style>
  