<template>
  <div class="d-flex justify-content-left align-items-center">
    <button class="btn btn-sm btn-secondary me-2" v-if="$store.getters.hasPerm('view_documents') && this.item.document" :disabled="disabledDocument" @click="downloadDocument">
      Doklad
    </button>
    <button class="btn btn-sm me-2"
      :disabled="approvedDocument === 'Schváleno' || disabledKRP"
      :class="(approvedDocument === 'Schválit' || approvedDocument === 'Schválit import') ? 'btn-light' : 'btn-success'"
      @click="approveDocument"
      v-if="(this.item.paired_bank_at || (this.item.document_type && this.item.document_type.financial_type === 'V'))
        && !this.item.rejected_at && $store.getters.hasPerm('approve_finances')">
      {{ approvedDocument }}
    </button>
    <button class="btn btn-sm me-2 btn-danger"
      :disabled="disabledKRP || this.item.rejected_at"
      @click="rejectDocument"
      v-if="(!this.item.approved_at && !this.item.paired_bank_at
        && (this.item.document_type && this.item.document_type.financial_type === 'V')) && $store.getters.hasPerm('approve_finances')">
      {{ this.item.rejected_at ? "Zamítnuto" : "Zamítnout" }}
    </button>
    <button class="btn btn-sm" :class="getPaidClass()"
      :disabled="!this.item.pay_from.id
        || !this.item.pay_to.id
        || this.item.payment_error
        || approvedDocument === 'Schválit'
        || paidDocument === 'Vráceno'
        || paidDocument === 'Zaplaceno'
        || paidDocument === 'Autorizace'
        || paidDocument === 'Odesláno'"
      @click="payDocument"
      v-if="(this.item.paired_bank_at || (this.item.document_type && this.item.document_type.financial_type === 'V' && this.item.approved_at))
        && $store.getters.hasPerm('pay_finances')">
      {{ paidDocument }}
    </button>
  </div>
</template>


<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  computed: {
    disabledDocument: function() {
      return !this.item.document;
    },
    disabledKRP: function() {
      if (this.$store.getters.hasPerm('manage_all_regions')) {
        return false;
      }
      if (this.$store.getters.user.krp_region_id && !this.item.region.id) {
        return true;
      }
      if (this.$store.getters.user.krp_region_id && this.item.region.id) {
        return !(this.$store.getters.user.krp_region_id === this.item.region.id);
      }
      return false;
    },
    approvedDocument: function () {
      if (this.item.approved_at) {
        return "Schváleno";
      }
      if (this.item.paired_bank_at) {
        return "Schválit import";
      }
      return "Schválit"
    },
    paidDocument: function() {
      if (this.item.returned_at) {
        return "Vráceno"
      }
      if (this.item.paired_bank_at) {
        return "Zaplaceno"
      }
      if (this.item.payment_error) {
        return "Chyba"
      }
      if (this.item.exported_bank_at) {
        return "Autorizace";
      }
      if (this.item.paid_at) {
        return "Odesláno";
      }
      return "Zaplatit"
    },
    documentNumber: function () {
      if (this.item.document_number) {
        return this.item.document_number.toString().trim();
      } else if (this.item.vs) {
        return this.item.vs.toString().trim();
      } else {
        return this.item.id.toString();
      }
    }
  },
  methods: {
    downloadDocument: function () {
      if (!this.$store.getters.hasPerm('view_documents')) {
        return;
      }

      this.$store.getters.api.get("/finance/" + this.item.id + "/document",
        {
          responseType: 'blob'
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // determine file extension from Content-Type header
          const contentType = response.headers['content-type'];
          let fileExtension = '';
          switch (contentType) {
            case 'application/pdf':
              fileExtension = '.pdf';
              break;
            case 'image/jpeg':
              fileExtension = '.jpg';
              break;
            case 'image/png':
              fileExtension = '.png';
              break;
            case 'image/gif':
              fileExtension = '.gif';
              break;
            default:
              console.error('Unsupported content type:', contentType);
              return;
          }

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', (this.item.vs ? this.item.vs : this.item.id) + fileExtension); //create name
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }).catch((error) => {
          console.error('Error downloading file:', error);
        });
    },
    approveDocument: function () {
      this.$store.getters.api.put('/finance/approve', {ids: [this.item.id]}).then(function (response) {
        const resData = response.data;
        if (resData.items.length > 0) {
          this.item.approved_at = resData.items[0].approved_at;
        }
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    rejectDocument: function () {
      this.$store.getters.api.put('/finance/reject', {ids: [this.item.id]}).then(function (response) {
        const resData = response.data;
        if (resData.items.length > 0) {
          this.item.rejected_at = resData.items[0].rejected_at;
        }
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    payDocument: function () {
      this.$store.getters.api.put('/finance/pay', {ids: [this.item.id]}).then(function (response) {
        const resData = response.data;
        this.item.paid_at = resData.items[0].paid_at;
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    getPaidClass() {
      return this.item.paired_bank_at
        ? 'btn-success'
        : this.item.payment_error
        ? 'btn-danger'
        : !this.item.paid_at
        ? 'btn-light'
        : 'btn-secondary';
    },
  },
}
</script>


<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";
.btn-light {
  background-color: $yellow-light !important;
  border-color: $yellow-main !important;
}

.btn-light:hover {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
}

.btn-light:active,
.btn-light:target {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
}

</style>