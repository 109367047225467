<template>
  <div class="row p-4">
    <div class="col-12 col-xl-8 px-4">
      <div class="col-auto pb-3">
        <button class="btn btn-primary text-nowrap me-2" @click="saveData()">
          <icon icon="Edit" />
          &nbsp;Uložit
        </button>
        <button class="btn btn-secondary text-nowrap" @click="this.$router.push({ name: 'finances' })">
          Zavřít bez uložení
        </button>
      </div>
      <DocumentDetail :document="data" :editable="editable" :bankAccounts="bankAccounts" @update-document="handleDocUpdate" @file-changed="handleFileChanged" />
    </div>
  </div>
</template>


<script>
import DocumentDetail from "@/components/DocumentDetail.vue";

export default {
  components: {
    DocumentDetail,
  },
  data() {
    return {
      editable: true,
      data: {
        amount_paid: 0,
        approved_by: {},
        chapter: {},
        created_by: {
          id: this.$store.getters.user.id,
          name: this.$store.getters.user.name
        },
        document_type: {},
        already_paid_document: null,
        paid_by: {},
        pay_from: {},
        pay_to: {
          account_number: '',
          account_prefix: '',
          bank_number: '',
          bic: '',
          iban: '',
          name: '',
        },
        region: {},
        subchapter: {},
      },
      documentFiles: null,
      bankAccounts: [],
      bankAccountsOther: [],
    }
  },
  created: function () {
    if (!this.$store.getters.hasPerm('select_details_finances')) {
      this.setDefaultValues();
    }
    this.$store.getters.api.get("/finance/accounts").then(response => {
      this.bankAccounts = [];
      const sorted = response.data.items.sort((a, b) => {
        if (a.name === null && b.name === null) {
          return 0;
        } else if (a.name === null) {
          return -1;
        } else if (b.name === null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      }); // Sort accounts by name
      sorted.forEach((account) => {
        if (account.account_type === 'intern') {
          this.bankAccounts.push(account);
        } else {
          this.bankAccountsOther.push(account);
        }
      })
    });
  },
  methods: {
    handleDocUpdate({ where, property, newValue }) {
      switch (where) {
        case 'document_type':
          this.data.document_type[property] = newValue;
          if (this.data.document_type && this.data.document_type.name === "Výdaje člena" && !this.data.vs) {
            this.generateVS();
          }
          break;
        case 'region':
          this.data.region[property] = newValue;
          break;
        case 'chapter':
          this.data.chapter[property] = newValue;
          break;
        case 'subchapter':
          this.data.subchapter[property] = newValue;
          break;
        case 'pay_to':
          this.data.pay_to[property] = newValue;
          break;
        case 'pay_from':
          this.data.pay_from[property] = newValue;
          break;
        default:
          this.data[property] = newValue;
      }
    },
    handleFileChanged(newDocumentFiles) {
      this.documentFiles = newDocumentFiles;
    },
    setDefaultValues() {
      const typesAll = this.$store.getters.docTypes;
      this.data.document_type = typesAll.find(docType => docType.name === "Výdaje člena");
      this.generateVS();
    },
    generateVS() {
      // generate random number with 10 digits (not starting with 0)
      this.data.vs = Math.floor(1000000000 + Math.random() * 9000000000);
    },
    saveData() {
      if (!this.documentFiles
          || Object.keys(this.data.document_type).length === 0
          || Object.keys(this.data.region).length === 0
          || !this.data.description
          || !this.data.amount
          || (this.data.document_type && this.data.document_type.financial_type === "V" && !this.data.vs)
          || !((this.data.pay_to.account_number && this.data.pay_to.bank_number) || this.data.pay_to.iban)) {
        if (this.data.document_type && this.data.document_type.financial_type === "V") {
          alert("Musíte zadat povinné údaje: doklad, typ dokladu, organizace, org. složka, popis plnění, částka, VS, číslo účtu");
        } else {
          alert("Musíte zadat povinné údaje: doklad, typ dokladu, organizace, org. složka, popis plnění, částka, číslo účtu");
        }
        return;
      }
      const numberPattern = /^\d*$/; // regex pattern for numbers only
      if ((this.data.vs && !numberPattern.test(this.data.vs))
          || (this.data.ks && !numberPattern.test(this.data.ks))
          || (this.data.ss && !numberPattern.test(this.data.ss))) {
        alert('VS, KS a SS musí obsahovat číselnou hodnotu');
        return
      }
      if ((this.data.pay_to.account_number && !numberPattern.test(this.data.pay_to.account_number))
          || (this.data.pay_to.account_prefix && !numberPattern.test(this.data.pay_to.account_prefix))
          || (this.data.pay_to.bank_number && !numberPattern.test(this.data.pay_to.bank_number))) {
        alert('Číslo bankovního účtu musí obsahovat číselnou hodnotu');
        return
      }
      this.$store.getters.api.post("/finance/add", this.data)
        .then(function (response) {
          this.data.id = response.data.items.id;
          if (this.documentFiles) {
            this.uploadDocToServer(); // return Promise
          }
        }.bind(this))
        .then(() => {
          this.$router.push({ name: 'finance-detail', params: { id: this.data.id } });
        })
        .catch(function (error) {
          alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
        });
    },
    uploadDocToServer: function () {
      let formData = new FormData();
      Array.from(this.documentFiles).forEach(function (file) {
        formData.append('document', file);
        this.$store.getters.api.post("/finance/" + this.data.id + "/document",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then();
      }.bind(this));
    },
  }
}
</script>


<style scoped lang="scss">

</style>
