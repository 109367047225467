<template>
  <!-- <div class="row align-items-end pb-3 topmargin">
    <div class="col-12 d-inline-flex justify-content-end">
      <button class="btn btn-primary dropdown text-nowrap" type="button" id="membership" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        v-if="$store.getters.hasPerm('change_personfunction') || $store.getters.hasPerm('delete_personfunction')">
        <icon icon="ChevronDownWhite" />
        &nbsp;Členství
      </button>
      <div class="dropdown-menu" aria-labelledby="membership">
        <a v-if="$store.getters.hasPerm('change_personfunction')" class="dropdown-item" href="#">Ukončit členství</a>
        <a v-if="$store.getters.hasPerm('change_personfunction')" class="dropdown-item" href="#">Pozastavit</a>
        <a v-if="$store.getters.hasPerm('delete_personfunction')" class="dropdown-item" href="#">Smazat</a>
      </div>
    </div>
  </div> -->
  <div class="row align-items-end pb-3 topmargin pt-5">
    <div class="col-12 py-3 justify-content-between d-flex">
      <h2>Aktuální funkce</h2>
      <div>
        <button type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#membership"
        v-if="$store.getters.user.id == memberId && notMember"
        @click="setCandidatures()">
        Stát se členem
        </button>
        <button type="button"
        class="btn btn-primary ms-2"
        data-bs-toggle="modal"
        data-bs-target="#addFunction"
        v-if="$store.getters.hasPerm('add_personfunction')">
        <icon icon="Plus" />
      </button>
    </div>
    </div>
    <div v-if="this.functionsCurrent.length > 0" class="row py-2 row-func align-items-center">
      <div class="col-5"></div>
      <div class="col-2"><b>Od</b></div>
      <div class="col-2"><b>Do</b></div>
      <div class="col-3"></div>
    </div>
    <div v-else class="row row-func align-items-center">
      <div class="col-12 py-2">Žádné funkce</div>
    </div>
    <div v-for="(row, index) in this.functionsCurrent" :key="index" class="row row-func align-items-center">
      <div class="col-5 py-2">{{ row.czech_name }} {{ getRegion(row) }}
        <template v-if="row.note && $store.getters.hasPerm('view_notes_personfunction')">
          <br />- {{ row.note }}
        </template>
      </div>
      <div class="col-2 py-2">{{ row.created_at }}</div>
      <div class="col-2 py-2">{{ row.lasted_to }}</div>
      <div class="col-3 d-inline-flex">
        <button
          v-if="$store.getters.hasPerm('change_personfunction')"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#update"
          @click="setDetails(row)">
          Upravit
        </button>
        <button
          v-if="$store.getters.hasPerm('delete_personfunction')"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#terminate"
          @click="setDetails(row)">
          Ukončit
        </button>
      </div>
    </div>
    <h2 class="col-12 py-3">Historické funkce</h2>
    <div v-if="this.functionsHistorical.length > 0" class="row py-2 row-func align-items-center">
      <div class="col-5"></div>
      <div class="col-2"><b>Od</b></div>
      <div class="col-2"><b>Do</b></div>
      <div class="col-3"></div>
    </div>
    <div v-else class="row row-func align-items-center">
      <div class="col-12 py-2">Žádné funkce</div>
    </div>
    <div v-for="(row, index) in this.functionsHistorical" :key="index" class="row py-2 row-func align-items-center">
      <div class="col-5">{{ row.czech_name }} {{ getRegion(row) }}
        <template v-if="row.note && $store.getters.hasPerm('view_notes_personfunction')">
          <br />- {{ row.note }}
        </template>
      </div>
      <div class="col-2">{{ row.created_at }}</div>
      <div class="col-2">{{ row.lasted_to }}</div>
      <div class="col-3 d-inline-flex">
        <button
          v-if="$store.getters.hasPerm('change_personfunction')"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#update"
          @click="setDetails(row)">
          Upravit
        </button>
      </div>
    </div>
  </div>
  <Popup
    :header="'Přidat funkci'"
    :editable="true"
    id="addFunction"
    @save-data="postFunction"
    v-if="$store.getters.hasPerm('add_personfunction')">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="position" class="m-2">Funkce:</label>
        <select id="position" class="form-select my-2" v-model="member_function">
          <template v-for="position in $store.getters.positions">
            <option v-if="canGetFunction(position)" :value="position.id">{{ position.czech_name }}</option>
          </template>
        </select>
        <label for="note" class="m-2">Organizační složka:</label>
        <select class="form-select my-2" v-model="body">
          <option v-for="body in $store.getters.bodies" :key="body.id" :value="body.id">
            <template v-if="body.organization">
              {{ body.organization.name === 'Republikové orgány' ? body.name : `${body.acronym} - ${body.organization.name}` }}
            </template>
            <template v-else>
              {{ body.name }}
            </template>
          </option>
        </select>
        <label for="note" class="m-2">Region/pobočka:</label>
        <select class="form-select my-2" v-model="region">
          <option v-for="region in filterRegions" :key="region.id" :value="region.id">{{ region.name }}</option>
        </select>
        <label for="from" class="m-2">Platnost od:</label>
        <input id="from" type="date" class="form-control my-2" v-model="from">
        <label for="till" class="m-2">Platnost do (ponechte prázné pro dobu neurčitou):</label>
        <input id="till" type="date" class="form-control my-2" v-model="till">
      </div>
    </template>
  </Popup>
  <Popup
    :header="`Ukončit funkci ${name} k datu:`"
    :editable="true"
    id="terminate"
    @save-data="terminateFunction"
    v-if="$store.getters.hasPerm('delete_personfunction')">
    <template v-slot:editor>
      <div class="mb-3">
        <input type="date" class="form-control" v-model="date">
        <label for="note" class="m-2">Poznámka/důvod ukončení:</label>
        <textarea id="note" class="form-control" v-model="note"></textarea>
      </div>
    </template>
    </Popup>
  <Popup
    :header="`Upravit funkci ${name}:`"
    :editable="true"
    id="update"
    @save-data="updateFunction"
    v-if="$store.getters.hasPerm('change_personfunction')">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="dateFrom" class="m-2">Platnost od:</label>
        <input id="dateFrom" type="date" class="form-control" v-model="dateFrom">
        <label for="dateTill" class="m-2">Platnost do:</label>
        <input id="dateTill" type="date" class="form-control" v-model="dateTill">
        <label for="note" class="m-2">Poznámka/důvod ukončení:</label>
        <textarea id="note" class="form-control" v-model="note"></textarea>
      </div>
    </template>
    </Popup>
    <PopupLarge
    :header="'Žádost o členství'"
    :editable="true"
    :button="'Požádat o členství'"
    id="membership"
    @save-data="postMembership"
    v-if="$store.getters.user.id == memberId && notMember">
    <template v-slot:editor>
      <div class="mb-3">
        <div class="form_section">
          <h2>Politická minulost</h2>
          <label>Byl jsem členem následujících stran</label>
          <textarea class="form-control register_text_area" v-model="previousPoliticalParties"></textarea>
          <label>Kandidoval jsem v těchto volbách</label>
          <textarea class="form-control register_text_area" v-model="previousCandidatures"></textarea>
        </div>
        <div class="form_section">
          <h2>Členský příspěvek</h2>
          <label class="form-label">Zvolte výši příspěvku (povinné)</label>
          <div>
            <div v-for="c in contributions" :key="c" @click="setContribution(c)" class="contribution">
              {{ c }} Kč
            </div>
            <div @click="setAnotherContribution()" class="contribution">
              Jiný
            </div>
          </div>
          <label class="form-label w-100">Hodnota příspěvku</label>
          <input
          v-if="minimumContribution"
          v-model="contribution"
          :min="minimumContribution"
          :disabled="!enabledContribution"
          @change="checkContribution()"
          type="number"
          ref="contribution"
          required
          class="form-control">
        </div>
        <div class="form_section">
          <h2>Prohlášení</h2>
          <input type="checkbox" class="form-check-input" v-model="agreed" ref="agreed" required/>
          <label class="form-check-label ms-3">Prohlašuji, že</label>
          <div>
            <ul class="m-0">
              <li>nejsem členem jiné strany a splňuji podmínky členství podle článku 3 <a href="http://public.svobodni.cz/svobodni/stanovy-svobodni.pdf" target="_blank">stanov</a></li>
              <li>se ztotožňuji s programem strany, především s programovým prohlášením článku 2 <a href="http://public.svobodni.cz/svobodni/stanovy-svobodni.pdf" target="_blank">stanov</a></li>
              <li>nebyl jsem spolupracovníkem, tajným spolupracovníkem ani zaměstnancem Státní bezpečnosti (StB), ani některého z těchto útvarů: I. správa SNB (rozvědka), III. správa SNB (vojenská kontrarozvědka), IV. správa SNB (sledování), V. správa SNB (ochrana ústavních činitelů), VI. správa SNB (zpravodajská technika)</li>
              <li>všechny údaje v tomto formuláři jsou pravdivé a souhlasím s jejich zpracováním Svobodnými</li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </PopupLarge>
</template>


<script>
import { CONFIG } from "@/config.js";
import Popup from '@/components/Popup.vue';
import PopupLarge from '@/components/PopupLarge.vue';

export default {
  components: {
    Popup,
    PopupLarge
  },
  emits: ['reload-data'],
  data() {
    return {
      date: "",
      dateFrom: "",
      dateTill: "",
      from: "",
      till: "",
      note: "",
      name: "",
      functionId: null,
      functionsCurrent: [],
      functionsHistorical: [],
      member_function: null,
      body: null,
      region: null,
      contribution: null,
      contributions: [],
      minimumContribution: null,
      enabledContribution: false,
      previousCandidatures: null,
      previousPoliticalParties: null,
      agreed: null,
      not_for_member: ["Příznivec",
        "Nezaplacený Příznivec",
        "Nový Příznivec",
        "Zájemce o členství",
        "Zájemce o členství - pohovor schválen",
        "Zájemce o členství - KRP schválen",
        "Zájemce o členství - KRP neschválen"]
    }
  },
  created: function () {
    const today = new Date();
    this.from = today.getFullYear() + "-" + String((today.getMonth() + 1)).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');
    this.date = today.getFullYear() + "-" + String((today.getMonth() + 1)).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');

    this.$store.getters.api.get("/contributions").then(function (response) {
      this.contributions = response.data.members;
      this.minimumContribution = Math.min.apply(Math, this.contributions);
    }.bind(this));
  },
  props: {
    memberId: null,
    isActiveMember: false,
    domesticRegion: {},
    member: {},
  },
  computed: {
    regions() {
      const countries = this.$store.getters.countries;
      const regions = this.$store.getters.regions;
      const branches = this.$store.getters.branches;
      const orgAll = countries.concat(regions).concat(branches);
      return orgAll;
    },
    filterRegions() {
      var body = null;
      for (var i = 0; i < this.$store.getters.bodies.length; i++){
        var b = this.$store.getters.bodies[i];
        if (b.id == this.body) {
          body = b;
        }
      };
      if (!body) {
        return this.regions;
      }

      if (body.acronym != "KrP") {
        return this.$store.getters.countries.concat(this.$store.getters.regions);
      }

      var regions = []
      var allRegions =  this.regions;

      for (var i = 0; i < allRegions.length; i++){
        if ((allRegions[i].parent && body.organization.id == allRegions[i].parent.id) || body.organization.id == allRegions[i].id) {
          regions.push(allRegions[i]);
        }
      }

      return regions;
    },
    notMember() {
      return !this.functionsCurrent.some(item =>
        item.czech_name === "Člen" ||
        item.czech_name === "Nezaplacený Člen" ||
        item.czech_name === "Zájemce o členství" ||
        item.czech_name === "Zájemce o členství - pohovor schválen" ||
        item.czech_name === "Zájemce o členství - KRP schválen"
      );
    }
  },
  watch: {
    member_function: {
      handler: function (newVal) {
        const position = this.$store.getters.positions.find(position => position.id === newVal);

        if (position && position.default_years) {
          const today = new Date();
          this.till = String(today.getFullYear() + position.default_years) + "-" + String((today.getMonth() + 1)).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');
        } else {
          this.till = "";
        }

        if (position && position.default_body) {
          if (position.default_body === "REP") {
            const body = this.$store.getters.bodies.find(b => b.acronym === "ReP");
            this.body = body.id;
            this.region = body.organization.id;
          } else if (position.default_body === "KRP") {
            if (this.domesticRegion.id) {
              const body = this.$store.getters.bodies.find(b => b.acronym === "KrP" && b.organization.id === this.domesticRegion.id);
              this.body = body.id;
              this.region = body.organization.id;
            } else {
              this.body = null;
              this.region = null;
            }
          } else {
            this.body = null;
            this.region = null;
          }
        } else {
          this.body = null;
          this.region = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    setCandidatures() {
      this.previousCandidatures = this.member.previous_candidatures;
      this.previousPoliticalParties = this.member.previous_political_parties;
    },
    getRegion(row) {
      if (row.organization) {
        return `(${row.organization.name})`;
      }
      if (row.body && row.body.organization) {
        return `(${row.body.organization.name})`;
      }
      return '';
    },
    canGetFunction(func) {
      const exists = this.functionsCurrent.some(function(el) {
        return el.czech_name === func.czech_name;
      });
      if (exists && func.czech_name != 'Koordinátor') {
        return false;
      }
      if (this.isActiveMember && this.not_for_member.includes(func.czech_name)) {
        return false;
      }
      if (func.membership_required) {
        return this.isActiveMember;
      }
      return true;
    },
    terminateFunction() {
      this.$store.getters.api.put("/member/" + this.memberId + "/functions", { date_from: null, date_till: this.date, id: this.functionId, note: this.note }).then(function (response) {
        this.$emit('reload-data'); // Reload data to display changes
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se ukončit funkci. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    updateFunction() {
      this.$store.getters.api.put("/member/" + this.memberId + "/functions", { date_from: this.dateFrom, date_till: this.dateTill, id: this.functionId, note: this.note }).then(function (response) {
        this.$emit('reload-data'); // Reload data to display changes
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    postFunction() {
      if (!this.member_function) {
        return;
      }
      const data = {
        from: this.from,
        till: this.till,
        id: this.member_function,
        body: this.body,
        region: this.region
      }
      this.$store.getters.api.post("/member/" + this.memberId + "/functions", data).then(function (response) {
        this.$emit('reload-data'); // Reload data to display changes
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se vytvořit funkci. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    postMembership() {
      if (!this.contribution || this.contribution < this.minimumContribution || !this.agreed) {
        alert("Musíte zvolit výši členského příspěvku a potvrdit prohlášení.")
        return;
      }

      const data = {
        contribution: this.contribution,
        previous_candidatures: this.previousCandidatures,
        previous_political_parties: this.previousPoliticalParties,
      }

      console.log(data);

      this.$store.getters.api.post("/member/" + this.memberId + "/membership", data).then(function (response) {
        this.$emit('reload-data'); // Reload data to display changes
        alert("Děkujeme! Vaši žádost o členství jsme přijali a budeme vás informovat o dalším postupu.")
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se vytvořit funkci. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    setDetails(row) {
      this.functionId = row.id;
      if (row.created_at !== "" && row.created_at !== null) {
        this.dateFrom = this.parseCustomDate(row.created_at).toISOString().split("T")[0];
      } else {
        this.dateFrom = "";
      }
      if (row.lasted_to !== "" && row.lasted_to !== null) {
        this.dateTill = this.parseCustomDate(row.lasted_to).toISOString().split("T")[0];
      } else {
        this.dateTill = "";
      }
      this.name = row.czech_name;
      if (row.note !== "" && row.note !== null) {
        this.note = row.note;
      } else {
        this.note = "";
      }
    },
    parseCustomDate(dateString) {
      const [day, month, year] = dateString.split('.').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    },
    loadData: function () {
      this.$store.getters.api.get("/member/" + this.memberId + "/functions").then(function (response) {
        this.functionsCurrent = response.data.current_roles.sort((a, b) => {
          return this.parseCustomDate(b.created_at) - this.parseCustomDate(a.created_at);
        });
        this.functionsHistorical = response.data.historical_roles.sort((a, b) => {
          return this.parseCustomDate(b.created_at) - this.parseCustomDate(a.created_at);
        });
      }.bind(this));
    },
    setContribution: function (c) {
      this.contribution = c;
      this.enabledContribution = false;
    },
    setAnotherContribution: function () {
      this.enabledContribution = true;
    },
    checkContribution: function () {
      if (this.contribution < this.minimumContribution) {
        this.contribution = this.minimumContribution;
      }
    },
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

h2 {
  font-size: 24px;
}

.topmargin {
  margin-top: 120px;
}

.row-func {
  border-bottom: 1px solid #ccc;
}

.form_section {
    border-radius: 20px;
    border: 1px solid $green-light;
    padding: 20px;
    margin-top: 10px;
}

.contribution {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid $green-light;
}
</style>