<template>
  <div class="col-12 mt-5">
    <div class="justify-content-between d-flex">
      <h2>Další údaje</h2>
      <button type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addAdditionalInfo"
        @click="resetMessage()"
        v-if="$store.getters.hasPerm('add_additionalinfoperson')">
        <icon icon="Plus" />
      </button>
    </div>
    <div class="mt-4">
      <span v-if="additionalInfos.length === 0" class="my-2">Žádné informace</span>
      <div class="row align-items-center py-1"
        v-for="(additionalInfo, index) in additionalInfos"
        :key="index"
        v-if="$store.getters.hasPerm('view_additionalinfoperson')">
        <div class="col-3">
          <span><strong>{{ additionalInfo.key }}</strong></span>
        </div>
        <div class="col-5">
          <span>{{ additionalInfo.value }}</span>
          <template v-if="additionalInfo.link">
            <span> (<a :href="additionalInfo.link" target="_blank">Odkaz</a>)</span>
          </template>
        </div>
        <div class="col-2">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#editAdditionalInfo"
            @click="copyInfo(additionalInfo)">Upravit</button>
        </div>
        <div class="col-2">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#deleteAdditionalInfo"
            @click="copyInfo(additionalInfo)">Smazat</button>
        </div>
      </div>
    </div>
  </div>
  <Popup ref="addAdditionalInfo"
    :header="'Nová informace'"
    :editable="true"
    id="addAdditionalInfo"
    @save-data="postAdditionalInfo"
    v-if="$store.getters.hasPerm('add_additionalinfoperson')"
  >
    <template v-slot:editor>
        <div>
            <div class="my-2">
              <strong>Klíč</strong><br>
              <input class="form-control" v-model="key">
            </div>
            <div class="mb-2">
              <strong>Hodnota</strong><br>
              <textarea class="form-control" v-model="value"></textarea>
            </div>
        </div>
    </template>
  </Popup>
  <Popup ref="editAdditionalInfo"
    :header="'Editovat další informaci'"
    :body="message"
    :editable="true"
    id="editAdditionalInfo"
    @save-data="editAdditionalInfo"
    v-if="$store.getters.hasPerm('change_additionalinfoperson')"
  >
    <template v-slot:editor>
        <div>
            <div class="my-2">
              <strong>Klíč</strong><br>
              <span>{{ key }}</span>
            </div>
            <div class="mb-2">
              <strong>Hodnota</strong><br>
              <textarea class="form-control" v-model="value"></textarea>
            </div>
        </div>
    </template>
  </Popup>
  <Popup
    :header="'Opravdu smazat další informaci?'"
    :button="'Smazat'"
    id="deleteAdditionalInfo"
    ref="deleteAdditionalInfo"
    @save-data="deleteAdditionalInfo"
    v-if="$store.getters.hasPerm('delete_additionalinfoperson')"
  >
  <template v-slot:body>
        <div>
            <div class="my-2">
              <strong>Klíč</strong><br>
              <span>{{ key }}</span>
            </div>
            <div class="mb-2">
              <strong>Hodnota</strong><br>
              <span>{{value }}</span>
            </div>
        </div>
    </template>
  </Popup>
</template>


<script>
import Popup from '@/components/Popup.vue';

export default {
  emits: ['reload-data'],
  components: {
    Popup
  },
  props: {
    memberId: {
      required: true
    }
  },
  data() {
    return {
      additionalInfos: [],
      key: null,
      value: null,
      infoId: null
    }
  },
  created: function () {
    this.loadData();
  },
  methods: {
    resetMessage: function () {
      this.key = null;
      this.value = null;
      this.infoId = null;
    },
    copyInfo: function (info) {
      this.infoId = info.id;
      this.key = info.key;
      this.value = info.value;
    },
    postAdditionalInfo: function () {
      this.additionalInfos.forEach(function(info){
        if (info.key == this.key) {
          alert("Nesmí být zadán stejný klíč jako je u daného člověka již zadán.")
          return
        }
      }.bind(this));
      this.$store.getters.api.post("/member/" + this.memberId + "/additional_info", {"key": this.key, "value": this.value}).then(function (response) {
        this.resetMessage();
        this.loadData();
      }.bind(this));

    },
    editAdditionalInfo: function () {
      this.$store.getters.api.put("/member/" + this.memberId + "/additional_info/" + this.infoId, {"value": this.value}).then(function (response) {
        this.resetMessage();
        this.loadData();
      }.bind(this));
    },
    deleteAdditionalInfo: function () {
      this.$store.getters.api.delete("/member/" + this.memberId + "/additional_info/" + this.infoId).then(function (response) {
        this.resetMessage();
        this.loadData();
      }.bind(this));
    },
    loadData: function () {
      this.$store.getters.api.get("/member/" + this.memberId + "/additional_info").then(function (response) {
        this.additionalInfos = response.data.additional_info;
      }.bind(this));
    }
  }
}
</script>



<style scoped lang="scss">
  .row {
    border-bottom: 1px solid #ccc;
  }
</style>