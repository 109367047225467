<template>
  <div class="container-fluid px-0 px-lg-4">
    <Navbar :pageTitle="pageTitle"/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"

export default {
  components: {
    Navbar
  },
  computed: {
    pageTitle: function () {
      const titleArray = this.$route.meta.title;
      if (!titleArray) {
        document.title = "Interní systém";
        return { title: [], before: [] };
      }
      const titleString = titleArray.join(' / ');
      document.title = "Interní systém - " + titleString;
      return this.$route.meta;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>