<template>
  <div class="row">
    <div class="col-6 font-weight-bold py-2">
      Název
    </div>
    <div class="col-2 font-weight-bold py-2">
      Údaje
    </div>
    <div class="col-4 font-weight-bold py-2 d-inline-flex" v-if="this.$store.getters.user.id == this.$route.params.id">
      <span class="ms-auto">Sdílet veřejně</span>
    </div>
  </div>
  <div>
    <MemberDetailRow header="VS"
      :body="member.id"
    />
    <MemberDetailRow header="Jméno, tituly"
      :body="member.name"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      sharedAlways>
      <template v-slot:editor>
        {{ member.name }}
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editName"
          @click="prefillName">
          Editovat
        </button>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Datum narození"
      :body="member.date_of_birth"
      :editableCheckbox="editable"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :inputType="'date'"
      forSharing
      :shared="shared('date_of_birth')"
      @sharing="(newValue) => handleBodyChange('sharing', 'date_of_birth', newValue)"
      @body="(newValue) => handleBodyChange('data', 'date_of_birth', newValue)"
    />
    <MemberDetailRow header="Zaplaceno do"
      :body="member.paid_till"
    />
    <MemberDetailRow header="Beran"
      :editable="editable"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('newsletter')"
      @sharing="(newValue) => handleBodyChange('sharing', 'newsletter', newValue)">
      <template v-slot:body>
        <div>
          {{ member.newsletter ? 'Odebíráno' : 'Neodebíráno' }}
        </div>
      </template>
      <template v-slot:editor>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="member.newsletter">
          <label class="form-check-label" for="flexSwitchCheckDefault">{{ member.newsletter ? 'Odebíráno' : 'Neodebíráno' }}</label>
        </div>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Telefonní číslo"
      :body="member.phone"
      :editable="editable"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('phone')"
      @sharing="(newValue) => handleBodyChange('sharing', 'phone', newValue)"
      @body="(newValue) => handleBodyChange('data', 'phone', newValue)"
    />
    <MemberDetailRow header="Email"
      :body="member.user.email"
      :editable="editable"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('email')"
      @sharing="(newValue) => handleBodyChange('sharing', 'email', newValue)"
      @body="(newValue) => handleBodyChange('user', 'email', newValue)"
    />
    <MemberDetailRow header="Trvalý pobyt"
      :body="member.domestic_address.full_address"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('domestic_address')"
      @sharing="(newValue) => handleBodyChange('sharing', 'domestic_address', newValue)">
      <template v-slot:editor>
        {{ member.domestic_address.full_address }}
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editDomestic">
          Editovat
        </button>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Korespondenční adresa"
      :body="member.postal_address.full_address"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('postal_address')"
      @sharing="(newValue) => handleBodyChange('sharing', 'postal_address', newValue)">
      <template v-slot:editor>
        {{ member.postal_address.full_address }}
        <button type="button"
          class="btn btn-sm btn-outline-secondary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#editPostal">
          Editovat
        </button>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Kraj"
      :body="member.domestic_region.name"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      sharedAlways>
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedDomesticRegion">
          <option v-for="region in domestic_regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </option>
        </select>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Pobočka"
      :body="member.domestic_branch.name"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      sharedAlways>
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedDomesticBranch">
          <option v-for="branch in domestic_branches" :key="branch.id" :value="branch.id">
            {{ branch.name }}
          </option>
        </select>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Hostuje v kraji"
      :body="member.guest_region.name"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      sharedAlways>
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedGuestRegion">
          <option v-for="region in guest_regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </option>
        </select>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Hostuje v pobočce"
      :body="member.guest_branch.name"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      sharedAlways>
      <template v-slot:editor>
        <select class="form-select w-auto flex-fill" v-model="selectedGuestBranch">
          <option v-for="branch in guest_branches" :key="branch.id" :value="branch.id">
            {{ branch.name }}
          </option>
        </select>
      </template>
    </MemberDetailRow>
    <MemberDetailRow header="Dřívější kandidatury"
      :body="member.previous_candidatures"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('previous_candidatures')"
      @sharing="(newValue) => handleBodyChange('sharing', 'previous_candidatures', newValue)"
      @body="(newValue) => handleBodyChange('data', 'previous_candidatures', newValue)"
    />
    <MemberDetailRow header="Dřívější členství v politických stranách"
      :body="member.previous_political_parties"
      :editable="editable && this.$store.getters.hasPerm('change_person')"
      :editableCheckbox="editable"
      forSharing
      :shared="shared('previous_political_parties')"
      @sharing="(newValue) => handleBodyChange('sharing', 'previous_political_parties', newValue)"
      @body="(newValue) => handleBodyChange('data', 'previous_political_parties', newValue)"
    />
    <MemberDetailRow header="Text pro kandidaturu do volebního systému"
      :body="member.description"
      :editable="editable"
      :editableCheckbox="editable"
      forSharing
      sharedAlways
      @body="(newValue) => handleBodyChange('data', 'description', newValue)"
    />
    <MemberDetailRow header="Jaká organizace" body="Svobodní"/>
  </div>
  <Popup ref="domesticAddress"
    :header="'Editovat adresu trvalého pobytu'"
    :editable="true"
    id="editDomestic"
    @save-data="setDomestic">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="street" class="m-2">Ulice:</label>
        <input id="street" type="text" class="form-control my-2" v-model="debouncedStreet" @input="debouncedSearch">
        <label for="city" class="m-2">Město:</label>
        <input id="city" type="text" class="form-control my-2" v-model="debouncedCity" @input="debouncedSearch">
        <label for="zip" class="m-2">PSČ:</label>
        <input id="zip" type="text" class="form-control my-2" v-model="debouncedZip" @input="debouncedSearch">
      </div>
      <div class="mb-3">
        <p v-if="this.ruian.ruian_id" class="m-2">Adresa úspěšně ověřena pod RUIAN ID: {{ this.ruian.ruian_id }}</p>
        <p v-else-if="this.ruian.street == '' || this.ruian.city == '' || this.ruian.zip == ''" class="m-2">Pokud nevyplníte všechna pole, bude uložena prázdná adresa.</p>
        <p v-else class="m-2">Adresa není platná podle RUIAN!<br>Zkontrolujte, zda jste ji zadali správně.</p>
      </div>
    </template>
  </Popup>
  <Popup ref="postalAddress"
    :header="'Editovat korespondenční adresu'"
    :editable="true"
    id="editPostal"
    @save-data="setPostal">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="street" class="m-2">Ulice:</label>
        <input id="street" type="text" class="form-control my-2" v-model="debouncedStreet" @input="debouncedSearch">
        <label for="city" class="m-2">Město:</label>
        <input id="city" type="text" class="form-control my-2" v-model="debouncedCity" @input="debouncedSearch">
        <label for="zip" class="m-2">PSČ:</label>
        <input id="zip" type="text" class="form-control my-2" v-model="debouncedZip" @input="debouncedSearch">
      </div>
      <div class="mb-3">
        <p v-if="this.ruian.ruian_id" class="m-2">Adresa úspěšně ověřena pod RUIAN ID: {{ this.ruian.ruian_id }}</p>
        <p v-else-if="this.ruian.street == '' || this.ruian.city == '' || this.ruian.zip == ''" class="m-2">Pokud nevyplníte všechna pole, bude uložena prázdná adresa.</p>
        <p v-else class="m-2">Adresa není platná podle RUIAN!<br>Zkontrolujte, zda jste ji zadali správně.</p>
      </div>
    </template>
  </Popup>
  <Popup ref="name"
    :header="'Editovat jméno'"
    :editable="true"
    id="editName"
    @save-data="setName">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="prefix" class="m-2">Tituly před jménem:</label>
        <input id="prefix" type="text" class="form-control my-2" v-model="namePrefix">
        <label for="first" class="m-2">Křestní jméno (jména):</label>
        <input id="first" type="text" class="form-control my-2" v-model="nameFirst">
        <label for="last" class="m-2">Příjmení:</label>
        <input id="last" type="text" class="form-control my-2" v-model="nameLast">
        <label for="suffix" class="m-2">Tituly za jménem:</label>
        <input id="suffix" type="text" class="form-control my-2" v-model="nameSuffix">
      </div>
    </template>
  </Popup>
</template>


<script>
import MemberDetailRow from '@/components/MemberDetailRow.vue';
import Popup from '@/components/Popup.vue';

export default {
  components: {
    MemberDetailRow,
    Popup
  },
  emits: ['update-member'],
  props: {
    member: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      debouncedZip: '',
      debouncedCity: '',
      debouncedStreet: '',
      namePrefix: '',
      nameFirst: '',
      nameLast: '',
      nameSuffix: '',
      ruian: {
        id: null,
        zip: '',
        city: '',
        street: '',
        ruian_id: null,
      },
      debouncedSearch: this.debounce(this.updateSearch),
    }
  },
  methods: {
    handleBodyChange(where, property, newValue) {
      this.$emit('update-member', { where, property, newValue });
    },
    debounce(func, timeout = 500) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    updateSearch() {
      this.ruian.zip = this.debouncedZip;
      this.ruian.city = this.debouncedCity;
      this.ruian.street = this.debouncedStreet;
    },
    validateAddress(ruian) {
      if (!ruian.zip || !ruian.city || !ruian.street) {
        return null;
      }
      this.$store.getters.api.post("/ruian", ruian).then(function (response) {
        if (response.data.ruian) {
          this.ruian.id = response.data.ruian.id;
          this.ruian.ruian_id = response.data.ruian.ruian_id;
          this.ruian.zip = response.data.ruian.zip;
          this.ruian.city = response.data.ruian.city;
          this.ruian.street = response.data.ruian.street;
        } else {
          this.ruian.id = null;
          this.ruian.ruian_id = null;
        }
      }.bind(this))
      .catch(function (error) {
        this.ruian.id = null;
        this.ruian.ruian_id = null;
        console.log(error);
      });
      return this.ruian.ruian_id;
    },
    setName() {
      if (this.nameFirst && this.nameLast) {
        this.handleBodyChange('data', 'name_prefix', this.namePrefix);
        this.handleBodyChange('data', 'first_name', this.nameFirst);
        this.handleBodyChange('data', 'last_name', this.nameLast);
        this.handleBodyChange('data', 'name_suffix', this.nameSuffix);
        let name = ''
        if (this.namePrefix) {
          name = this.namePrefix + ' ';
        }
        name += `${this.nameFirst} ${this.nameLast}`
        if (this.nameSuffix) {
          name += `, ${this.nameSuffix}`
        }
        this.handleBodyChange('data', 'name', name);
      } else {
        alert("Vyplňte alespoň křestní jméno a příjmení.");
      }
      this.namePrefix = '';
      this.nameFirst = '';
      this.nameLast = '';
      this.nameSuffix = '';
    },
    prefillName() {
      this.namePrefix = this.member.name_prefix;
      this.nameFirst = this.member.first_name;
      this.nameLast = this.member.last_name;
      this.nameSuffix = this.member.name_suffix;
    },
    setDomestic() {
      if (this.ruian.zip && this.ruian.city && this.ruian.street) {
        this.handleBodyChange('domestic_address', 'street', this.ruian.street);
        this.handleBodyChange('domestic_address', 'city', this.ruian.city);
        this.handleBodyChange('domestic_address', 'zip', this.ruian.zip);
        this.handleBodyChange('domestic_address', 'ruian', this.ruian.id);
        this.handleBodyChange('domestic_address', 'full_address', `${this.ruian.street}, ${this.ruian.zip} ${this.ruian.city}`);
      } else {
        this.handleBodyChange('domestic_address', 'street', '');
        this.handleBodyChange('domestic_address', 'city', '');
        this.handleBodyChange('domestic_address', 'zip', '');
        this.handleBodyChange('domestic_address', 'ruian', null);
        this.handleBodyChange('domestic_address', 'full_address', '');
      }
      this.resetAddress();
    },
    setPostal() {
      if (this.ruian.zip && this.ruian.city && this.ruian.street) {
        this.handleBodyChange('postal_address', 'street', this.ruian.street);
        this.handleBodyChange('postal_address', 'city', this.ruian.city);
        this.handleBodyChange('postal_address', 'zip', this.ruian.zip);
        this.handleBodyChange('postal_address', 'ruian', this.ruian.id);
        this.handleBodyChange('postal_address', 'full_address', `${this.ruian.street}, ${this.ruian.zip} ${this.ruian.city}`);
      } else {
        this.handleBodyChange('postal_address', 'street', '');
        this.handleBodyChange('postal_address', 'city', '');
        this.handleBodyChange('postal_address', 'zip', '');
        this.handleBodyChange('postal_address', 'ruian', null);
        this.handleBodyChange('postal_address', 'full_address', '');
      }
      this.resetAddress();
    },
    resetAddress() {
      this.debouncedZip = '';
      this.debouncedCity = '';
      this.debouncedStreet = '';
      this.ruian.ruian_id = null;
      this.ruian.zip = '';
      this.ruian.city = '';
      this.ruian.street = '';
    }
  },
  watch: {
    ruian: {
      handler: function(val){
        this.ruian.ruian_id = this.validateAddress(val);
      },
      deep: true,
    },
  },
  computed: {
    domestic_branches() {
      const branchesAll = this.$store.getters.branches;
      if (this.member.domestic_region.id) {
        const branches = branchesAll.filter(branch => branch.parent && branch.parent.id === this.member.domestic_region.id);
        branches.unshift({
          id: null,
          name: "Žádná pobočka"
        });
        return branches;
      }
      const branches = branchesAll.filter(branch => branch.name !== "Všechny pobočky");
      branches.unshift({
        id: null,
        name: "Žádná pobočka"
      });
      return branches;
    },
    guest_branches() {
      const branchesAll = this.$store.getters.branches;
      if (this.member.guest_region.id) {
        const branches = branchesAll.filter(branch => branch.parent && branch.parent.id === this.member.guest_region.id);
        branches.unshift({
          id: null,
          name: "Žádná pobočka"
        });
        return branches;
      }
      const branches = branchesAll.filter(branch => branch.name !== "Všechny pobočky");
      branches.unshift({
        id: null,
        name: "Žádná pobočka"
      });
      return branches;
    },
    domestic_regions() {
      const regionsAll = this.$store.getters.regions;
      const regions = regionsAll.filter(region => region.name !== "Všechny kraje");
      regions.unshift({
        id: null,
        name: "Žádný kraj"
      });
      return regions;
    },
    guest_regions() {
      const regionsAll = this.$store.getters.regions;
      const regions = regionsAll.filter(region => region.name !== "Všechny kraje");
      regions.unshift({
        id: null,
        name: "Žádný kraj"
      });
      return regions;
    },
    shared() {
      return function (property) {
        if (!this.member) {
          return false;
        }
        if (this.member.sharing && this.member.sharing.hasOwnProperty(property)) {
          return this.member.sharing[property];
        }
        return false;
      }
    },
    selectedDomesticRegion: {
      get() {
        return this.member.domestic_region.id;
      },
      set(newValue) {
        if (newValue === null) {
          this.member.domestic_region = { id: null, name: "Žádný kraj"}
          this.member.domestic_branch = { id: null, name: "Žádná pobočka"}
          return;
        }
        this.member.domestic_region = this.$store.getters.regions.find(region => region.id === newValue);
        this.member.domestic_branch = { id: null, name: "Žádná pobočka"}
      }
    },
    selectedDomesticBranch: {
      get() {
        return this.member.domestic_branch.id;
      },
      set(newValue) {
        if (newValue === null) {
          this.member.domestic_branch = { id: null, name: "Žádná pobočka"}
          return;
        }
        this.member.domestic_branch = this.$store.getters.branches.find(branch => branch.id === newValue);
        this.member.domestic_region = this.$store.getters.regions.find(region => region.id === this.member.domestic_branch.parent.id);
      }
    },
    selectedGuestRegion: {
      get() {
        return this.member.guest_region.id;
      },
      set(newValue) {
        if (newValue === null) {
          this.member.guest_region = { id: null, name: "Žádný kraj"}
          this.member.guest_branch = { id: null, name: "Žádná pobočka"}
          return;
        }
        this.member.guest_region = this.$store.getters.regions.find(region => region.id === newValue);
        this.member.guest_branch = { id: null, name: "Žádná pobočka"}
      }
    },
    selectedGuestBranch: {
      get() {
        return this.member.guest_branch.id;
      },
      set(newValue) {
        if (newValue === null) {
          this.member.guest_branch = { id: null, name: "Žádná pobočka"}
          return;
        }
        this.member.guest_branch = this.$store.getters.branches.find(branch => branch.id === newValue);
        this.member.guest_region = this.$store.getters.regions.find(region => region.id === this.member.guest_branch.parent.id);
      }
    }
  }
}
</script>


<style scoped lang="scss">
  .row {
    border-bottom: 1px solid #ccc;
  }
</style>