<template>
  <div class="row justify-content-center align-items-center mb-5">
      <div class="col-12 col-md-8">
          <h1>Registrační formulář</h1>
          <div class="form_section">
              <h2>Základní údaje</h2>
              <label>Tituly před jménem</label>
              <input type="text" class="form-control" v-model="namePrefix"/>
              <label>Křestní jméno (povinné)</label>
              <input type="text" class="form-control" ref="firstName" required v-model="firstName"/>
              <label>Příjmení (povinné)</label>
              <input type="text" class="form-control"  ref="lastName" required v-model="lastName"/>
              <label>Tituly za jménem</label>
              <input type="text" class="form-control" v-model="nameSuffix"/>
              <label class="form-label">Datum narození (povinné)</label>
              <input type="date" class="form-control" ref="birthDate" required v-model="birthDate"/>
              <label class="form-label">Email (povinné)</label>
              <input type="email" class="form-control" ref="email" required v-model="email"/>
              <label class="form-label">Telefon (povinné)</label>
              <input type="phone" class="form-control" required v-model="phone"/>
          </div>
          <div class="form_section">
              <h2>Trvalé bydliště</h2>
              <label class="form-label">PSČ (povinné)</label>
              <input type="number" class="form-control" v-model="zipPernament" ref="pernamentAddressZip" required>
              <label class="form-label">Město (povinné)</label>
              <select class="form-control" v-model="townPernament" :disabled="townsPernament == null || townsPernament.length == 0"
                  ref="pernamentAddressTown" required>
                  <option v-for="town in townsPernament" v-key="town.id">{{ town.name }}</option>
              </select>
              <label class="form-label">Kraj</label>
              <select class="form-control" v-model="regionPernament" :disabled="regionsPernament.length <= 1">
                  <option v-for="region in regionsPernament" v-key="region" :value="region">{{ region }}</option>
              </select>
              <label class="form-label">Okres</label>
              <select class="form-control" v-model="districtPernament" :disabled="districtsPernament.length <= 1">
                  <option v-for="district in districtsPernament" v-key="district" :value="district">{{ district }}</option>
              </select>
              <label class="form-label">Adresa (povinné)</label>
              <input type="text" class="form-control"
                  v-model="addressPernament" :disabled="!allowPernamentAddress"
                  ref="pernamentAddress" required/>
          </div>
          <div class="form_section">
              <h2>Uživatelské údaje</h2>
              <label>Uživatelské jméno - email (povinné)</label>
              <input type="text" class="form-control" v-model="email" disabled ref="username"  required/>
              <p class="form-warning" v-if="usernameFound">Uživatelské jméno není dostupné</p>
              <label>Heslo (povinné)</label>
              <input type="password" class="form-control" v-model="password" ref="password" required/>
              <label>Potvrzení hesla (povinné)</label>
              <input type="password" class="form-control" v-model="comfirmPassword"/>
              <p class="form-warning" v-if="!samePasswords">Hesla se neshodují</p>
          </div>
          <div class="form_section">
              <h2>Prohlášení</h2>
              <div class="form-check">
                  <input type="radio" class="form-check-input" id="memberYes" name="membershipStatus" value="yes" v-model="member" ref="memberYes" required>
                  <label class="form-check-label" for="memberYes">Byl(a) jsem v minulosti členem Svobodných.</label>
              </div>
              <div class="form-check">
                  <input type="radio" class="form-check-input" id="memberNo" name="membershipStatus" value="no" v-model="member" ref="memberNo" required>
                  <label class="form-check-label" for="memberNo">Nebyl(a) jsem členem Svobodných.</label>
              </div>
              <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="agreed" ref="agreed" required/>
                  <label class="form-check-label">Prohlašuji, že všechny údaje v tomto formuláři jsou pravdivé, a souhlasím s jejich zpracováním Svobodnými.</label>
              </div>
              <p>* V případě uhrazení přízniveckého příspěvku slouží formulář jako registrační formulář dle Stanov čl. 4 odst. 1</p>
              <button class="btn btn-primary mt-2 text-white" @click="save()">Registrovat</button>
          </div>
      </div>
      <component is="script" :src="'https://www.google.com/recaptcha/api.js?render=' + publicToken"></component>
  </div>
</template>

<script>
import { CONFIG } from "@/config.js";

export default {
  data: function () {
      return {
          namePrefix: null,
          nameSuffix: null,
          firstName: null,
          lastName: null,
          birthDate: null,
          email: null,
          phone: null,
          password: null,
          comfirmPassword: null,
          agreed: null,
          zipPernament: null,
          townPernament: null,
          regionPernament: null,
          districtPernament: null,
          addressPernament: null,
          townsPernament: [],
          townsPernamentRequest: null,
          regionsPernament: [],
          districtsPernament: [],
          captchaToken: null,
          publicToken: null,
          usernameFound: false,
          member: '',
          returnUrl: '',
      }
  },
  computed: {
      allowPernamentAddress: function(){
          return this.zipPernament && this.townPernament && this.regionPernament && this.districtPernament;
      },
      samePasswords: function () {
          return this.password === this.comfirmPassword
      }
  },
  watch: {
      zipPernament: function (val) {
          if (this.townsPernamentRequest != null) {
              clearTimeout(this.townsPernamentRequest);
          }
          this.townsPernament = [];
          this.townPernament = null;
          this.townsPernamentRequest = setTimeout(function () {
              this.findTowns(val).then(function (response) {
                  this.townsPernament = response.data.items;
              }.bind(this));
          }.bind(this), 500)
      },
      username: function () {
          if (this.townsPernamentRequest != null) {
              clearTimeout(this.townsPernamentRequest);
          }
          this.townsPernamentRequest = setTimeout(function () {
              this.$store.getters.api.get("/check_username", {
                  params: { username: this.email }
              }).then(function (r) {
                  this.usernameFound = r.data.found
              }.bind(this));
          }.bind(this), 500)
      },
      townPernament: function (val) {
          if (val == null) {
              return
          }

          this.findDistrict(this.zipPernament, val).then(function (response) {
              this.districtsPernament = response.data.items;
              if (this.districtsPernament.length == 1) {
                  this.districtPernament = this.districtsPernament[0];
              }
          }.bind(this));

          this.findRegion(this.zipPernament, val).then(function (response) {
              this.regionsPernament = response.data.items;
              if (this.regionsPernament.length == 1) {
                  this.regionPernament = this.regionsPernament[0];
              }
          }.bind(this));

      }
  },
  created: function () {
      this.returnUrl = this.$route.query.return_url;
      this.publicToken = CONFIG.captchaPublicToken;
  },
  methods: {
      findTowns: function (zip) {
          return this.$store.getters.api.get("/towns", { params: { zip: zip } })
      },
      findDistrict: function (zip, town) {
          return this.$store.getters.api.get("/district", { params: { zip: zip, town: town} })
      },
      findRegion: function (zip, town) {
          return this.$store.getters.api.get("/region", { params: { zip: zip, town: town } })
      },
      getCaptchaToken: function () {
          grecaptcha.ready(function () {
              grecaptcha.execute(this.publicToken, { action: 'submit' }).then(function (token) {
                  this.captchaToken = token;
              }.bind(this))
          }.bind(this))
      },
      valid: function () {
          if (!this.$refs["firstName"].reportValidity()) {
              return false;
          }
          if (!this.$refs["lastName"].reportValidity()) {
              return false;
          }
          if (!this.$refs["birthDate"].reportValidity()) {
              return false;
          }
          if (!this.$refs["email"].reportValidity()) {
              return false;
          }
          if (!this.$refs["pernamentAddressZip"].reportValidity()) {
              return false;
          }
          if (!this.$refs["pernamentAddressTown"].reportValidity()) {
              return false;
          }
          if (!this.$refs["pernamentAddress"].reportValidity()) {
              return false;
          }
          if (!this.$refs["username"].reportValidity()) {
              return false;
          }
          if (!this.$refs["password"].reportValidity()) {
              return false;
          }
          if (!this.$refs["memberYes"].reportValidity() || !this.$refs["memberNo"].reportValidity()) {
              return false;
          }
          if (!this.$refs["agreed"].reportValidity()) {
              return false;
          }
          if (!this.samePasswords) {
              return false;
          }
          if (this.usernameFound) {
              return false;
          }
          return true;
      },
      save: function () {
          grecaptcha.ready(function () {
              grecaptcha.execute(CONFIG.captchaPublicToken, { action: 'submit' }).then(function (token) {
                  this.captchaToken = token;
                  this.saveData();
              }.bind(this));
          }.bind(this));
      },
      saveData: function () {
          // do not save if all is not found
          if (!this.valid()) {
              return
          }

          if (this.member === 'yes') {
              alert("Pokud jste již v minulosti byl(a) členem Svobodných, neregistrujte se, ale kontaktujte prosím kancelar@svobodni.cz.")
              return
          }

          var data = {
              name_prefix: this.namePrefix,
              name_suffix: this.nameSuffix,
              first_name: this.firstName,
              last_name: this.lastName,
              birth_date: this.birthDate,
              email: this.email,
              phone: this.phone,
              password: this.password,
              zip_pernament: this.zipPernament,
              town_pernament: this.townPernament,
              region_pernament: this.regionPernament,
              district_pernament: this.districtPernament,
              address_pernament: this.addressPernament,
              captcha_token: this.captchaToken
          }

          this.$store.getters.api.post(
              "/register_committee", data
          ).then(
              function(r){
                  window.location.href = this.returnUrl;
              }.bind(this)
          ).catch(
              function (r) {
                  if (r.response) {
                      alert(r.response.data.error);
                  }
              }.bind(this)
          )
      }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
.form_section {
  border-radius: 20px;
  border: 1px solid $green-light;
  padding: 20px;
  margin-top: 10px;
}

.form-warning {
  color: red;
  font-weight: 700;
  margin: 0;
}

.register_text_area {
  min-height: 100px;
}
</style>