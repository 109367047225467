<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title mt-2 mb-3">{{ $store.getters.user.name }}</h3>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title pb-2">Vaše údaje</h4>
        <router-link class="btn btn-outline-secondary btn-sm" :to="{ name: 'member-detail', params: { id: $store.getters.user.id } }">Aktualizovat</router-link>
      </div>
      <p class="card-text">Trvalý pobyt: <b>{{ $store.getters.user.domestic_address?.full_address }}</b></p>
      <p class="card-text">Krajské sdružení: {{ $store.getters.user.domestic_region?.name ?? "-" }}</p>
      <p class="card-text">Pobočka: {{ $store.getters.user.domestic_branch?.name ?? "-" }}</p>
      <p v-if="$store.getters.user.guest_region.name" class="card-text">Hostujete v kraji: {{ $store.getters.user.guest_region?.name ?? "-" }}</p>
      <p v-else-if="$store.getters.user.guest_branch.name" class="card-text">Nehostujete v žádném jiném kraji.</p>
      <p v-if="$store.getters.user.guest_branch.name" class="card-text">Hostujete v pobočce: {{ $store.getters.user.guest_branch?.name ?? "-" }}</p>
      <p v-else-if="$store.getters.user.guest_region.name" class="card-text">Nehostujete v žádné jiné pobočce.</p>
      <p v-if="!$store.getters.user.guest_branch.name && !$store.getters.user.guest_region.name" class="card-text">Nehostujete v žádném jiném kraji ani pobočce.</p>
      <h4 class="card-title border-bottom pb-2">Sdílené kontakty</h4>
      <p class="card-text">
        <icon icon="Mail" />
        &nbsp;
        <a href="mailto:{{ $store.getters.user.user.email }}">
          {{ $store.getters.user.user.email }}
        </a><br />
        <icon icon="Phone" />
        &nbsp;
        {{ $store.getters.user.phone }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.card {
  margin: 20px auto;
}
</style>
