<template>
  <div class="form-group row px-3">
    <div class="d-flex justify-content-end flex-wrap pt-4 pb-1">
      <input
        type="text"
        class="form-control flex-fill w-auto m-2"
        v-model="debouncedSearchValue"
        @input="debouncedSearch"
        placeholder="HLEDAT ID, jméno, telefon, email"
      />
      <multiselect
        v-model="member_types"
        :options="positions"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte funkce"
        label="czech_name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="domestic_regions"
        :options="$store.getters.regions"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte kraj"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="domestic_branches"
        :options="branches"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte pobočku"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <select class="form-select w-auto flex-fill m-2" v-model="view" @change="setFields">
        <option v-for="view in views" :key="view.id" :value="view">{{ view.name }}</option>
      </select>
      <div class="d-inline-flex">
        <button class="btn btn-primary d-inline-flex m-2" @click="showAdvanced()">
          <icon icon="ChevronDownWhite" />
          &nbsp;Pokročilé
        </button>
        <button type="button" class="btn btn-light m-2" @click="setFields">Výchozí</button>
        <button type="button" class="btn btn-danger dropdown m-2" id="views" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          v-if="$store.getters.hasPerm('add_settings') || $store.getters.hasPerm('change_settings')">+</button>
        <div class="dropdown-menu" aria-labelledby="views">
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#editView" href="#" v-if="$store.getters.hasPerm('change_settings') && this.view.id">Aktualizovat tento pohled</a>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addView" href="#" v-if="$store.getters.hasPerm('add_settings')">Uložit jako nový pohled</a>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteView" href="#" v-if="$store.getters.hasPerm('delete_settings') && this.view.id">Smazat tento pohled</a>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#csv" href="#" v-if="$store.getters.hasPerm('export_person')">Export do CSV</a>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#pdf" href="#" v-if="$store.getters.hasPerm('export_person')">Export do PDF</a>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#xlsx" href="#" v-if="$store.getters.hasPerm('export_person')">Export do XLSX</a>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showingAdvanced" class="col-12">
        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
          <label for="guest" class="m-2">Hostuje v jiném kraji/pobočce</label>
          <input type="checkbox" id="guest" class="form-check-input m-2" v-model="guest"/>
          <select v-if="this.filters.guest" class="form-select w-auto flex-fill m-2" v-model="guest_region_id">
            <option v-for="region in $store.getters.regions" :key="region.id" :value="region.id">{{ region.name }}</option>
          </select>
          <select class="form-select w-auto flex-fill m-2" v-model="body">
            <option v-for="body in $store.getters.bodies" :key="body.id" :value="body.id">
              <template v-if="body.organization">
                {{ body.organization.name === 'Republikové orgány' ? body.name : `${body.acronym} - ${body.organization.name}` }}
              </template>
              <template v-else>
                {{ body.name }}
              </template>
            </option>
          </select>
          <select class="form-select w-auto flex-fill m-2" v-model="member_status">
            <option v-for="membership in $store.getters.memberships" :key="membership.id" :value="membership.id">{{ membership.name }}</option>
          </select>
        </div>
        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
          <label for="historical" class="m-2">Prohledat i historické funkce</label>
          <input type="checkbox" id="historical" class="form-check-input m-2" v-model="historical"/>
          <label for="function-from" class="m-2">Zvolené funkce platné od</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="function-from" v-model="debouncedFunctionFrom" @input="debouncedSearch"/>
          <label for="function-from" class="m-2">do</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="function-till" v-model="debouncedFunctionTill" @input="debouncedSearch"/>
        </div>
        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center pb-4">
          <label for="paid-from" class="m-2">Úhrada členského příspěvku od</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="paid-from" v-model="debouncedPaidFrom" @input="debouncedSearch"/>
          <label for="paid-from" class="m-2">do</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="paid-till" v-model="debouncedPaidTill" @input="debouncedSearch"/>
          <label for="birth-from" class="m-2">Datum narození od</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="birth-from" v-model="debouncedBirthFrom" @input="debouncedSearch"/>
          <label for="birth-from" class="m-2">do</label>
          <input type="date" class="form-control w-auto flex-fill m-2" id="birth-till" v-model="debouncedBirthTill" @input="debouncedSearch"/>
        </div>
      </div>
    </transition>
    <Popup
      :header="'Zadejte název nového pohledu'"
      :editable="true"
      id="addView"
      @save-data="saveView"
      v-if="$store.getters.hasPerm('add_settings')"
    />
    <Popup
      :header="'Aktualizovat pohled'"
      :editable="false"
      id="editView"
      :body="user_id == this.$store.getters.user.id ? `Přepsat nastavení pohledu ${this.view.name}?` : 'Nastavení lze měnit pouze u vlastních pohledů.'"
      :button="user_id == this.$store.getters.user.id ? 'Přepsat' : 'OK'"
      @save-data="editView"
      v-if="$store.getters.hasPerm('change_settings')"
    />
    <Popup
      :header="'Smazat pohled'"
      :editable="false"
      id="deleteView"
      :body="user_id == this.$store.getters.user.id ? `Opravdu smazat pohled ${this.view.name}?` : 'Mazat lze pouze vlastní pohledy.'"
      :button="user_id == this.$store.getters.user.id ? 'Smazat' : 'OK'"
      @save-data="deleteView"
      v-if="$store.getters.hasPerm('delete_settings')"
    />
    <Popup
      :header="'Export do CSV'"
      :editable="true"
      id="csv"
      :button="'Exportovat'"
      @save-data="exportCSV(csvName)"
      v-if="$store.getters.hasPerm('export_person')">
      <template v-slot:editor>
        <div class="mb-3">
          <p class="m-2">Exportují se označené řádky. Pokud nic neoznačíte, exportují se všechny záznamy podle aktuálních filtrů.</p>
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
        </div>
      </template>
    </Popup>
    <Popup
      :header="'Export do XLSX'"
      :editable="true"
      id="xlsx"
      :button="'Exportovat'"
      @save-data="exportXLSX(csvName)"
      v-if="$store.getters.hasPerm('export_person')">
      <template v-slot:editor>
        <div class="mb-3">
          <p class="m-2">Exportují se označené řádky. Pokud nic neoznačíte, exportují se všechny záznamy podle aktuálních filtrů.</p>
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
        </div>
      </template>
    </Popup>
    <Popup
      :header="'Export do PDF'"
      :editable="true"
      id="pdf"
      :button="'Exportovat'"
      @save-data="exportPDF(pdfName)"
      v-if="$store.getters.hasPerm('export_person')">
      <template v-slot:editor>
        <div class="mb-3">
          <p class="m-2">Exportují se označené řádky. Pokud nic neoznačíte, exportují se všechny záznamy podle aktuálních filtrů.</p>
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="pdfName">
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/components/Popup.vue';
import Multiselect from 'vue-multiselect'

export default {
  props: {
    settings: {
        type: Object,
        required: true
    },
    filters: {
        type: Object,
        required: true
    },
  },
  components: {
    Popup,
    Multiselect
  },
  mounted: function () {
    this.debouncedSearchValue = this.search;
    this.debouncedPaidFrom = this.paid_from;
    this.debouncedPaidTill = this.paid_till;
    this.debouncedFunctionFrom = this.function_from;
    this.debouncedFunctionTill = this.function_till;
    this.debouncedBirthFrom = this.birth_from;
    this.debouncedBirthTill = this.birth_till;
    this.getFullObjects();

    // Do not trigger changeFilters method in parent and reload data until watchers deal with the changes
    this.$nextTick(() => {
      this.noReload = false;
    });
  },
  data() {
    return {
      view: {
        id: null,
        name: "Výchozí pohled",
        settings_type: "members",
        user: null,
        group: null,
        data: null
      },
      reloadTimer: null,
      noReload: true,
      csvName: "",
      pdfName: "",
      showingAdvanced: false,
      debouncedSearchValue: "",
      debouncedFunctionFrom: null,
      debouncedFunctionTill: null,
      debouncedPaidFrom: null,
      debouncedPaidTill: null,
      debouncedBirthFrom: null,
      debouncedBirthTill: null,
      debouncedSearch: this.debounce(this.updateSearch),
      ...this.filters
    };
  },
  computed: {
    views() {
      const settings = this.$store.getters.settings;
      const views = settings.filter(view => view.settings_type === "members");
      views.unshift({
        id: null,
        name: "Výchozí pohled",
        settings_type: "members",
        user: null,
        group: null,
        data: null
      });
      return views;
    },
    user_id() {
      if (this.view) {
        return this.view.user;
      }
      return null;
    },
    branches() {
      const branchesAll = this.$store.getters.branches;
      if (this.domestic_regions && this.domestic_regions.length != 0) {
        const regions = [];
        this.domestic_regions.forEach(x => regions.push(x.id))
        return branchesAll.filter(branch => branch.parent && regions.includes(branch.parent.id));
      }
      return branchesAll;
    },
    positions: function () {
      // do not write to story data
      var p = [...this.$store.getters.positions];
      p.push({ "id": -1, "czech_name": "Žádná funkce" });
      return p;
    }
  },
  watch: {
    member_types: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("member_types", ids);
        }
      },
      deep: true,
    },
    domestic_regions: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("domestic_regions", ids);
          if (this.domestic_branches.length > 0 && ids.length > 0) {
            const branches = [];
            this.domestic_branches.forEach((branch) => {
              if (branch.parent && ids.includes(branch.parent.id)) {
                branches.push(branch);
              }
            });
            this.domestic_branches = branches;
          }
        }
      },
      deep: true,
    },
    domestic_branches: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("domestic_branches", ids);
        }
      },
      deep: true,
    },
    member_status: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("member_status", val);
        }
      },
      deep: true,
    },
    guest: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("guest", val);
        }
      },
      deep: true,
    },
    guest_region_id: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("guest_region_id", val);
        }
      },
      deep: true,
    },
    historical: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("historical", val);
        }
      },
      deep: true,
    },
    body: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("body", val);
        }
      },
      deep: true,
    },
  },
  methods: {
    changeFilters: function (key, val) {
      this.$emit('changeFilters', key, val);

      // Wait with reloading data in case more filters are being changed at once
      if(this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.reloadTimer = setTimeout(() => {
        this.reloadData();
      }, 100);
    },
    showAdvanced: function () {
      this.showingAdvanced = !this.showingAdvanced;
      this.$emit('showAdvanced');
    },
    limitText: function (count) {
      if (count <= 4) {
        return `a ${count} další`;
      }
      return `a ${count} dalších`;
    },
    debounce(func, timeout = 500) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    updateSearch() {
      this.changeFilters("search", this.debouncedSearchValue);
      this.changeFilters("function_from", this.debouncedFunctionFrom);
      this.changeFilters("function_till", this.debouncedFunctionTill);
      this.changeFilters("paid_from", this.debouncedPaidFrom);
      this.changeFilters("paid_till", this.debouncedPaidTill);
      this.changeFilters("birth_from", this.debouncedBirthFrom);
      this.changeFilters("birth_till", this.debouncedBirthTill);
    },
    resetFields: function() {
      this.changeFilters("search", "");
      this.debouncedSearchValue = "";
      this.changeFilters("domestic_regions", []);
      this.domestic_regions = [];
      this.changeFilters("domestic_branches", []);
      this.domestic_branches = [];
      this.changeFilters("member_status", null);
      this.member_status = null;
      this.changeFilters("member_types", []);
      this.member_types = [];
      this.changeFilters("columns", []);
      this.changeFilters("per_page", 50);
      this.changeFilters("sort_by", "id");
      this.changeFilters("sort_order", "D");
      this.changeFilters("guest", false);
      this.guest = false;
      this.changeFilters("guest_region_id", null);
      this.guest_region_id = null;
      this.changeFilters("historical", false);
      this.historical = false;
      this.changeFilters("function_from", null);
      this.debouncedFunctionFrom = null;
      this.changeFilters("function_till", null);
      this.debouncedFunctionTill = null;
      this.changeFilters("paid_from", null);
      this.debouncedPaidFrom = null;
      this.changeFilters("paid_till", null);
      this.debouncedPaidTill = null;
      this.changeFilters("birth_from", null);
      this.debouncedBirthFrom = null;
      this.changeFilters("birth_till", null);
      this.debouncedBirthTill = null;
      this.changeFilters("body", null);
      this.body = null;
      this.view = {
        id: null,
        name: "Výchozí pohled",
        settings_type: "members",
        user: null,
        group: null,
        data: null
      };
    },
    setFields: function () {
      if (!this.view || !this.view.data) {
        this.resetFields();
        return;
      }
      this.changeFilters("search", this.view.data.search || "");
      this.debouncedSearchValue = this.view.data.search || "";
      this.changeFilters("domestic_regions", this.view.data.domestic_regions || []);
      this.changeFilters("domestic_branches", this.view.data.domestic_branches || []);
      this.changeFilters("member_status", this.view.data.member_status || null);
      this.member_status = this.view.data.member_status || null;
      this.changeFilters("member_types", this.view.data.member_types || []);
      this.changeFilters("per_page", this.view.data.per_page || 50);
      this.changeFilters("sort_by", this.view.data.sort_by || "id");
      this.changeFilters("sort_order", this.view.data.sort_order || "D");
      this.changeFilters("guest", this.view.data.guest || false);
      this.guest = this.view.data.guest || false;
      this.changeFilters("guest_region_id", this.view.data.guest_region_id || null);
      this.guest_region_id = this.view.data.guest_region_id || null;
      this.changeFilters("historical", this.view.data.historical || false);
      this.historical = this.view.data.historical || false;
      this.changeFilters("function_from", this.view.data.function_from || null);
      this.debouncedFunctionFrom = this.view.data.function_from || null;
      this.changeFilters("function_till", this.view.data.function_till || null);
      this.debouncedFunctionTill = this.view.data.function_till || null;
      this.changeFilters("paid_from", this.view.data.paid_from || null);
      this.debouncedPaidFrom = this.view.data.paid_from || null;
      this.changeFilters("paid_till", this.view.data.paid_till || null);
      this.debouncedPaidTill = this.view.data.paid_till || null;
      this.changeFilters("birth_from", this.view.data.birth_from || null);
      this.debouncedBirthFrom = this.view.data.birth_from || null;
      this.changeFilters("birth_till", this.view.data.birth_till || null);
      this.debouncedBirthTill = this.view.data.birth_till || null;
      this.changeFilters("body", this.view.data.body || null);
      this.body = this.view.data.body || null;
      this.changeFilters("columns", this.view.data.columns || []);

      this.getFullObjects();
    },
    getFullObjects: function () {
      // get full objects for filter values
      this.member_types = [];
      this.filters.member_types.forEach((memberType) => {
        this.member_types.push(this.$store.getters.positions.find((item) => item.id === memberType));
      });
      this.domestic_regions = [];
      this.filters.domestic_regions.forEach((region) => {
        this.domestic_regions.push(this.$store.getters.regions.find((item) => item.id === region));
      });
      this.domestic_branches = [];
      this.filters.domestic_branches.forEach((branch) => {
        this.domestic_branches.push(this.branches.find((item) => item.id === branch));
      });
    },
    exportCSV: function (name) {
      this.$emit('exportCSV', name);
    },
    exportXLSX: function (name) {
      this.$emit('exportXLSX', name);
    },
    exportPDF: function (name) {
      this.$emit('exportPDF', name);
    },
    saveView: function (name) {
      this.$emit('saveView', name);
      this.reloadSettings();
    },
    editView: function () {
      if (this.user_id == this.$store.getters.user.id) {
        this.$emit('editView', this.view.id);
        this.reloadSettings();
      }
    },
    deleteView: function () {
      if (this.user_id == this.$store.getters.user.id) {
        this.$emit('deleteView', this.view.id);
        this.reloadSettings();
        this.resetFields();
      }
    },
    reloadSettings: function () {
      setTimeout(function () {
        this.$store.commit("reloadSettings");
      }.bind(this), 500);
    },
    reloadData: function () {
      this.$emit('reloadData');
    }
  },
}
</script>

<style scoped lang="scss">

</style>