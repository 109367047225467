<template>
  <div class="col-12 col-md-6 col-lg-12 col-xxl-6">
    <h2>Události</h2>
    <ul>
      <li v-if="logs.length === 0" class="my-2">Žádné události</li>
      <li v-if="$store.getters.hasPerm('view_log')" v-for="(log, index) in logs" :key="index" class="my-2">
        <strong>{{ log.created_at }}</strong>: {{ log.message_public }}
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    memberId: {
      required: true
    }
  },
  data() {
    return {
      logs: [],
    }
  },
  methods: {
    loadData: function () {
      this.$store.getters.api.get("/member/" + this.memberId + "/logs").then(function (response) {
        this.logs = response.data.items;
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">
h2 {
  font-size: 24px;
}
</style>