<template>
  <div class="btn-group px-4 py-3" role="group" aria-label="radio toggle tabs">
    <input type="radio" class="btn-check" name="branch" id="branch" autocomplete="off" v-model="selectedView" :value="1" checked>
    <label class="btn btn-outline-primary" for="branch">Pobočky</label>
  </div>
  <SettingsBranches v-if="selectedView === 1" />
</template>


<script>
import SettingsBranches from "@/components/SettingsBranches.vue";

export default {
  components: {
    SettingsBranches,
  },
  data() {
    return {
      selectedView: 1,
    }
  },
  methods: {
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.btn-outline-primary {
  border-color: $green-dark;
  color: $green-dark;
}

.btn-outline-primary:hover {
  border-color: $green-light;
  color: $green-light;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: $green-dark;
  border-color: $green-dark;
  color: #fff;
}
</style>
