<template>
  <div class="btn-group px-4 pt-3" role="group" aria-label="radio toggle tabs">
    <template
      v-for="(view, index) in views"
      :key="view.id">
      <input
        type="radio"
        class="btn-check"
        :name="'btnradio' + view.id"
        :id="'btnradio' + view.id"
        autocomplete="off"
        v-model="selectedView"
        :value="view"
        :checked="index === 0">
      <label
        class="btn btn-outline-primary"
        :for="'btnradio' + view.id">
        {{ view.name }}
      </label>
    </template>
  </div>
  <div class="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 px-4 pb-5 g-4">
    <div v-for="widget in filteredWidgets" :key="widget.title" class="col">
      <div class="card text-center bg-primary py-2 px-2">
        <DashboardWidget
          :title="widget.title"
          :description="widget.description"
          :icon="widget.icon"
          :link="widget.link"
          :userFriendly="widget.userFriendly"
        />
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('my_region')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Zařazení'"
          :description="'Můj kraj a pobočka:'">
          <template v-slot:body>
            <div class="mb-3">
              <h5 class="mt-1 mb-2">{{ my_region.region }}</h5>
              <ul>
                <li v-for="krp in my_region.krp">{{ krp.name }} ({{ krp.function }})</li>
              </ul>
              <h5 v-if="my_region.branch" class="mt-1 mb-2">Pobočka {{ my_region.branch }}</h5>
              <ul v-if="my_region.branch">
                <li v-for="coordinator in my_region.coordinators">{{ coordinator.name }} ({{ coordinator.function }})</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('payments')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přehled plateb'"
          :description="'Přehled členských příspěvků:'">
          <template v-slot:body>
            <div class="m-3">
              <div class="row">Zaplaceno do: {{ this.$store.getters.user.paid_till ? this.$store.getters.user.paid_till.split('-').reverse().join('.') : "nezaplaceno" }}</div>
              <div v-if="this.showUserPayments">
                <h4 class="row mt-3">Doporučená platba:</h4>
                <div class="row">
                  Platební údaje: {{ this.membershipPaymentDetailsRecommended }}
                </div>
                <div class="row">
                  <img v-if="this.qrCodeRecommended" :src="this.qrCodeRecommended" alt="QR kód pro platbu" class="qrcode mx-auto" />
                </div>
              </div>
              <div v-if="this.showUserPayments">
                <h4 class="row mt-3">Základní platba:</h4>
                <div class="row">
                  Platební údaje: {{ this.membershipPaymentDetailsBasic }}
                </div>
                <div class="row">
                  <img v-if="this.qrCodeBasic" :src="this.qrCodeBasic" alt="QR kód pro platbu" class="qrcode mx-auto" />
                </div>
              </div>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('subscription')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Odběry'"
          :description="'Seznam maillistů:'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('functions')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přehled funkcí'"
          :description="'Přehled aktivních funkcí a rolí:'">
          <template v-slot:body>
            <div class="m-3">
              <div v-for="(row, index) in this.functionsCurrent" :key="index" class="row">
                {{ row.czech_name }}{{ row.lasted_to ? ` (do ${row.lasted_to})` : ' (na dobu neurčitou)' }}
              </div>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('latest')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Poslední změny'"
          :description="'Přehled aktuálních informací:'">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li>co čeká ke schválení</li>
                <li>grafy</li>
                <li>poslední logy</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('region')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přijímání členů v kraji'"
          :description="stats_krp.region || ''">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li>Noví zájemci o členství za 1/3/12 měsíců: {{ stats_krp.members_30 || 0 }} / {{ stats_krp.members_90 || 0 }} / {{ stats_krp.members_365 || 0 }}</li>
                <li>Nově přijatí členové za 1/3/12 měsíců: {{ stats_krp.members_accepted_30 || 0 }} / {{ stats_krp.members_accepted_90 || 0 }} / {{ stats_krp.members_accepted_365 || 0 }}</li>
                <li>Noví zájemci o příznivectví za 1/3/12 měsíců: {{ stats_krp.supporters_30 || 0 }} / {{ stats_krp.supporters_90 || 0 }} / {{ stats_krp.supporters_365 || 0 }} </li>
                <li>Nově přijatí příznivci za 1/3/12 měsíců: {{ stats_krp.supporters_accepted_30 || 0 }} / {{ stats_krp.supporters_accepted_90 || 0 }} / {{ stats_krp.supporters_accepted_365 || 0 }} </li>
                <li>Průměrná doba trvání přijímacího procesu od podání přihlášky do doby, než se stane členem (za 1/3/12 měsíců): {{ stats_krp.membership_time_30 || 0 }} / {{ stats_krp.membership_time_90 || 0 }} / {{ stats_krp.membership_time_365 || 0 }} dnů</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('budget_region')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Rozpočet kraje' + ` k ${today}`"
          :description="(regionBudget.region || '') + ` ${thisYear}`">
          <template v-slot:body>
            <div class="row mb-3">
              <table>
                <tr>
                  <th>Rozpočet</th>
                  <th>Návrh [Kč]</th>
                  <th>Skutečnost [Kč]</th>
                  <th>[%]</th>
                </tr>
                <tr>
                  <td>Stav k 1.1.</td>
                  <td>{{ regionBudget.budget }}</td>
                  <td>{{ regionBudget.budget }}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Příjmy</td>
                  <td>{{ regionBudget.income_suggested }}</td>
                  <td>{{ regionBudget.income }}</td>
                  <td>{{ regionBudget.income_percent }}</td>
                </tr>
                <tr>
                  <td>Výdaje</td>
                  <td>{{ regionBudget.outcome_suggested }}</td>
                  <td>{{ regionBudget.outcome }}</td>
                  <td>{{ regionBudget.outcome_percent }}</td>
                </tr>
                <tr>
                  <td>Zůstatek</td>
                  <td>{{ regionBudget.budget_suggested }}</td>
                  <td>{{ regionBudget.balance }}</td>
                  <td>-</td>
                </tr>
              </table>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('budget')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Rozpočet krajů' + ` k ${today}`"
          :description="'přehled rozpočtu na rok ' + thisYear">
          <template v-slot:body>
            <div class="row mb-3" v-for="budget in budgets">
              <h5 class="mt-1 mb-2">{{ budget.region }}</h5>
              <table>
                <tr>
                  <th>Rozpočet</th>
                  <th>Návrh [Kč]</th>
                  <th>Skutečnost [Kč]</th>
                  <th>[%]</th>
                </tr>
                <tr>
                  <td>Stav k 1.1.</td>
                  <td>{{ budget.budget }}</td>
                  <td>{{ budget.budget }}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Příjmy</td>
                  <td>{{ budget.income_suggested }}</td>
                  <td>{{ budget.income }}</td>
                  <td>{{ budget.income_percent }}</td>
                </tr>
                <tr>
                  <td>Výdaje</td>
                  <td>{{ budget.outcome_suggested }}</td>
                  <td>{{ budget.outcome }}</td>
                  <td>{{ budget.outcome_percent }}</td>
                </tr>
                <tr>
                  <td>Zůstatek</td>
                  <td>{{ budget.budget_suggested }}</td>
                  <td>{{ budget.balance }}</td>
                  <td>-</td>
                </tr>
              </table>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('budget_region') || this.selectedView.data.widgets.includes('budget')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Finanční rozložení kapitol' + ` k ${today}`"
          :description="`Pro rok ${thisYear}`">
          <template v-slot:body>
            <div class="mb-2">
              <input type="checkbox" class="form-check-input" v-model="showAllChapters"><span class="ms-2 text-muted">Zobrazit všechny kapitoly</span>
            </div>
            <div class="row mb-3">
              <table>
                <tr>
                  <th>Název</th>
                  <th>Typ</th>
                  <th>Částka [Kč]</th>
                  <th>Počet</th>
                </tr>
                <template v-for="(chapter, i) in chapters" :key="i">
                  <tr v-if="(chapter && chapter.count) || showAllChapters">
                    <td>{{ chapter.name }}</td>
                    <td>
                      <span v-if="chapter.type == 'P'">Příjem</span>
                      <span v-else-if="chapter.type == 'V'">Výdaj</span>
                      <span v-else>{{ chapter.type }}</span>
                    </td>
                    <td>{{ chapter.sum }}</td>
                    <td>{{ chapter.count }}</td>
                  </tr>
                </template>
              </table>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('krp_applicants')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Žadatelé - pohovory'"
          :description="'zaregistrovaní žadatelé o členství k pohovorům'">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li v-if="stats_krp.to_interview.length === 0">Žádní žadatelé</li>
                <li v-else v-for="item in stats_krp.to_interview">{{ item }}</li>
              </ul>
            </div>
            <button type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="interviewRedirect">
              Zobrazit v registru
            </button>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('krp_interviewed')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Žadatelé - ke schválení'"
          :description="'absolventi po pohovoru ke schválení'">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li v-if="stats_krp.to_approve.length === 0">Žádní žadatelé</li>
                <li v-else v-for="item in stats_krp.to_approve">{{ item }}</li>
              </ul>
            </div>
            <button type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="toApproveRedirect">
              Zobrazit v registru
            </button>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('krp_approved')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Žadatelé - schválení, nezaplacení'"
          :description="'schválení žadatelé o členství, kteří ještě nezaplatili'">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li v-if="stats_krp.approved_unpaid.length === 0">Žádní žadatelé</li>
                <li v-else v-for="item in stats_krp.approved_unpaid">{{ item }}</li>
              </ul>
            </div>
            <button type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="approvedRedirect">
              Zobrazit v registru
            </button>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('election')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Volby'"
          :description="'Informace o nadcházejících/probíhajících volbách:'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('statements')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Stanoviska'"
          :description="'Čerstvá stanoviska strany:'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('republic')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Republikové orgány'"
          :description="'Složení, kontakty - stejné jako půjde na veřejný web'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('region_budget')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Rozpočet kraje' + ` k ${today}`"
          :description="(regionBudget.region || '') + ` ${thisYear}`">
          <template v-slot:body>
            <div class="row mb-3">
              <table>
                <tr>
                  <th>Rozpočet</th>
                  <th>Návrh [Kč]</th>
                  <th>Skutečnost [Kč]</th>
                  <th>[%]</th>
                </tr>
                <tr>
                  <td>Stav k 1.1.</td>
                  <td>{{ regionBudget.budget }}</td>
                  <td>{{ regionBudget.budget }}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Příjmy</td>
                  <td>{{ regionBudget.income_suggested }}</td>
                  <td>{{ regionBudget.income }}</td>
                  <td>{{ regionBudget.income_percent }}</td>
                </tr>
                <tr>
                  <td>Výdaje</td>
                  <td>{{ regionBudget.outcome_suggested }}</td>
                  <td>{{ regionBudget.outcome }}</td>
                  <td>{{ regionBudget.outcome_percent }}</td>
                </tr>
                <tr>
                  <td>Zůstatek</td>
                  <td>{{ regionBudget.budget_suggested }}</td>
                  <td>{{ regionBudget.balance }}</td>
                  <td>-</td>
                </tr>
              </table>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('records')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Zápisy z jednání'"
          :description="'Usnesení KrP:'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('region_members')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Členská základna'"
          :description="'Link na členskou základnu, příznivce a koordinátory v kraji do registru'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('records_files')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Usnesení orgánů'"
          :description="'Link na usnesení orgánů do files zvlášť ReP, ReV, komise'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('krp_election')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Volby v Praze'"
          :description="'info o volbách, které jsme měli v Praze včetně výsledků'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('krp_members')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přehled členů'"
          :description="'počet členů - ' + stats_krp.region">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li>Řádní: {{ stats_krp.members_total || 0 }}</li>
                <li>Nezaplacení: {{ stats_krp.members_unpaid || 0 }}</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('krp_supporters')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přehled příznivců'"
          :description="'počet příznivců - ' + stats_krp.region">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <li>Řádní: {{ stats_krp.supporters_total || 0 }}</li>
                <li>Nezaplacení: {{ stats_krp.supporters_unpaid || 0 }}</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('krp_branches')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Přehled poboček'"
          :description="'pobočky klik na registr s fitrem na pobočku, seznam koordinatoru pobocky'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('finances')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Finance'"
          :description="'doklady ke schválení'">
          <template v-slot:body>
            <div class="mb-3">
              <ul>
                <template v-for="item in unapproved_finances">
                  <li v-if="(this.$store.getters.user.krp_region_id && this.$store.getters.user.krp_region_id === item.id)
                    || !(this.selectedView.name === 'Krajský předseda' || this.selectedView.name === 'Krajský místopředseda')">
                    {{ item.region }}: {{ item.count }}
                  </li>
                </template>
              </ul>
            </div>
            <button type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="financesToApproveRedirect">
              Zobrazit
            </button>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <div v-if="this.selectedView.data.widgets.includes('rep_members')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Členové krajů'"
          :description="'přehled aktivních členů a příznivců v krajích'">
          <template v-slot:body>
            <div class="mb-3" v-for="item in stats_rep">
              <h5 class="mt-1">{{ item.region }}</h5>
              <ul>
                <li>členové: {{ item.members_total }}</li>
                <li>příznivci: {{ item.supporters_total }}</li>
              </ul>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
    <!-- <div v-if="this.selectedView.data.widgets.includes('rep_election')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Kalendář voleb'"
          :description="'termíny příštích voleb'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('rev_calendar')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Termíny jednání'"
          :description="'napojit kalendář'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('rev_materials')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Podklady'"
          :description="'prolink do files na složku REVREV / podsložku s datem'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <!-- <div v-if="this.selectedView.data.widgets.includes('rev_constitution')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Složení ReV'"
          :description="'link na registr s daným filtrem'">
        </DashboardWidgetDynamic>
      </div>
    </div> -->
    <div v-if="this.selectedView.data.widgets.includes('office')" class="col">
      <div class="card text-center py-2 px-2">
        <DashboardWidgetDynamic
          :title="'Kancelář'"
          :description="'přehled činností kanceláře'">
          <template v-slot:body>
            <div class="mb-3">
              <h5 class="mt-1">Zájemci o členství čekající na pohovor</h5>
              <ul>
                <li v-for="item in stats_office.to_interview">{{ item }}</li>
              </ul>
              <button type="button"
              class="btn btn-sm btn-outline-secondary mb-3"
              @click="interviewAllRedirect">
                Zobrazit v registru
              </button>
              <h5 class="mt-1">Žadatelé po pohovoru čekající na schválení</h5>
              <ul>
                <li v-for="item in stats_office.to_approve">{{ item }}</li>
              </ul>
              <button type="button"
              class="btn btn-sm btn-outline-secondary mb-3"
              @click="toApproveAllRedirect">
                Zobrazit v registru
              </button>
              <h5 class="mt-1">Schválení žadatelé, kteří ještě nezaplatili</h5>
              <ul>
                <li v-for="item in stats_office.approved_unpaid">{{ item }}</li>
              </ul>
              <button type="button"
              class="btn btn-sm btn-outline-secondary"
              @click="approvedAllRedirect">
                Zobrazit v registru
              </button>
            </div>
          </template>
        </DashboardWidgetDynamic>
      </div>
    </div>
  </div>
  <div class="row px-4">
    <div class="col-12 col-lg-6 col-xxl-5">
      <UserWidget />
    </div>
    <div class="col-12 col-lg-6 col-xxl-4">
      <button type="button"
        class="btn btn-primary mt-3"
        data-bs-toggle="modal"
        data-bs-target="#addNote"
        @click="resetMessage()"
        v-if="$store.getters.hasPerm('add_news')">
        Přidat novinku
      </button>
      <div class ="col-12" v-for="newsy in news" :key="newsy.id">
        <NewsWidget :news="newsy" @edit-news="setMessage"/>
      </div>
    </div>
    <div class="col-12 col-xxl-3">
      <SupportWidget />
    </div>
  </div>
  <Popup ref="newNote"
    :header="'Zveřejnit novinku'"
    :editable="true"
    id="addNote"
    @save-data="postNote"
    v-if="$store.getters.hasPerm('add_news')">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="title" class="m-2">Název:</label>
        <input id="title" type="text" class="form-control my-2" v-model="title">
        <label for="message" class="m-2">Zpráva:</label>
        <textarea id="message" class="form-control my-2" v-model="message"></textarea>
      </div>
    </template>
  </Popup>
  <Popup
    :header="'Editovat novinku'"
    :editable="true"
    id="editNote"
    @save-data="editNote"
    v-if="$store.getters.hasPerm('change_news')">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="title" class="m-2">Název:</label>
        <input id="title" type="text" class="form-control my-2" v-model="title">
        <label for="message" class="m-2">Zpráva:</label>
        <textarea id="message" class="form-control my-2" v-model="message"></textarea>
      </div>
    </template>
  </Popup>
</template>


<script>
import DashboardWidget from "@/components/DashboardWidget.vue";
import DashboardWidgetDynamic from "@/components/DashboardWidgetDynamic.vue";
import SupportWidget from "@/components/SupportWidget.vue";
import NewsWidget from "@/components/NewsWidget.vue";
import UserWidget from "@/components/UserWidget.vue";
import Popup from '@/components/Popup.vue';
import { CONFIG } from "@/config.js"

export default {
  components: {
    DashboardWidget,
    DashboardWidgetDynamic,
    SupportWidget,
    NewsWidget,
    UserWidget,
    Popup
  },
  data() {
    return {
      widgets: [],
      news: [],
      stats_office: {},
      stats_rep: [],
      stats_krp: {},
      my_region: {},
      unapproved_finances: [],
      budgets: [],
      chapters: [],
      qrCodeBasic: "",
      qrCodeRecommended: "",
      title: "",
      message: "",
      id: "",
      functionsCurrent: [],
      showAllChapters: false,
      selectedView: {
        id: null,
        name: "Výchozí pohled",
        settings_type: "dashboard",
        data: {
          widgets: ["register", "payments", "functions"]
        }
      },
      widgets: [
        {
          "name":"voting",
          "title":"Volební systém",
          "description":"Ve volebním systému naleznete volební místnost, archiv volebních protokolů a možnost podání kandidatury do nadcházejících voleb.",
          "link":"dashboard",
          "userFriendly":"vs.svobodni.cz",
          "icon": "HandFinger"
        },
        {
          "name":"files",
          "title":"Úložiště",
          "description":"Zde najdete oficiální dokumenty centrálních i krajských orgánů, vybrané dokumenty a návody.",
          "link":"dashboard",
          "userFriendly":"files.svobodni.cz",
          "icon":"FileDownload"
        },
        {
          "name":"forum",
          "title":"Fórum",
          "description":"Diskusní fórum se sekcemi pro členy, příznivce, orgány strany a pro prezentaci kandidátů.",
          "link":"dashboard",
          "userFriendly":"forum.svobodni.cz",
          "icon": "Message"
        },
        {
          "name":"wiki",
          "title":"Wiki",
          "description":"Informační stránky, databáze návodů, postupů a znalostí, kam může přispívat každý člen.",
          "link":"dashboard",
          "userFriendly":"wiki.svobodni.cz",
          "icon":"School"
        },
        {
          "name":"register",
          "title":"Sdílení kontaktů",
          "description":"Členové a příznivci sdílí své kontakty. Najděte Svobodné ve vašem okolí.",
          "link":"members",
          "userFriendly":"registr.svobodni.cz",
          "icon": "Users"
        }
      ],
    }
  },
  created: function () {
    this.loadData();
  },
  computed: {
    views() {
      const settings = this.$store.getters.settings;
      const views = settings.filter(view => view.settings_type === "dashboard");
      views.unshift({
        id: null,
        name: "Výchozí pohled",
        settings_type: "dashboard",
        data: {
          widgets: ["register", "payments", "functions"]
        }
      });
      return views;
    },
    filteredWidgets() {
      return this.widgets.filter(widget => this.selectedView.data.widgets.includes(widget.name));
    },
    regionBudget() {
      const krp_region_id = this.$store.getters.user.krp_region_id;
      if (krp_region_id) {
        return this.budgets.find(item => item.id === krp_region_id);
      }
      return null;
    },
    thisYear() {
      const today = new Date();
      return String(today.getFullYear());
    },
    today() {
      const today = new Date();
      return String(today.toLocaleDateString("cs-CZ"));
    },
    membershipPaymentDetailsBasic() {
      if (this.$store.getters.user) {
        const contribution = this.$store.getters.user.contribution;
        let info = "kontaktujte kancelář"
        if (contribution) {
          this.functionsCurrent.forEach((item) => {
            if (item.czech_name === "Člen" || item.czech_name === "Zájemce o členství - KRP schválen" || item.czech_name === "Nezaplacený Člen") {
              info = `${contribution} Kč na účet 2100382818/2010 s VS ${this.$store.getters.user.id} a zprávou pro příjemce: clensky prispevek (${this.$store.getters.user.name})`;
            }
            if (item.czech_name === "Příznivec" || item.czech_name === "Nezaplacený Příznivec" || item.czech_name === "Ukončené příznivectví" || item.czech_name === "Nový Příznivec") {
              info = `${contribution} Kč na účet 7505075050/2010 s VS ${this.$store.getters.user.id} a zprávou pro příjemce: dar priznivce (${this.$store.getters.user.name})`;
            }
          });
        }
        return info
      }
    },
    membershipPaymentDetailsRecommended() {
      if (this.$store.getters.user) {
        let info = "kontaktujte kancelář"
        this.functionsCurrent.forEach((item) => {
          if (item.czech_name === "Člen" || item.czech_name === "Zájemce o členství - KRP schválen" || item.czech_name === "Nezaplacený Člen") {
            info = `${CONFIG.recommendedPayment} Kč na účet 2100382818/2010 s VS ${this.$store.getters.user.id} a zprávou pro příjemce: clensky prispevek (${this.$store.getters.user.name})`;
          }
          if (item.czech_name === "Příznivec" || item.czech_name === "Nezaplacený Příznivec" || item.czech_name === "Ukončené příznivectví" || item.czech_name === "Nový Příznivec") {
            info = `${CONFIG.recommendedPayment} Kč na účet 7505075050/2010 s VS ${this.$store.getters.user.id} a zprávou pro příjemce: dar priznivce (${this.$store.getters.user.name})`;
          }
        });
        return info
      }
    },
    showUserPayments: function () {
      if (!this.$store.getters.user.paid_till) {
        return true;
      }
      var days = 90;
      return  new Date(new Date().getTime()+(days*24*60*60*1000)).getTime() > new Date(this.$store.getters.user.paid_till).getTime()
    }
  },
  methods: {
    setMessage(note) {
      this.title = note.title;
      this.message = note.message
      this.id = note.id
    },
    resetMessage() {
      this.message = "";
      this.title = "";
    },
    postNote: function () {
      this.$store.getters.api.post("/news", { title: this.title, message: this.message }).then(function (response) {
        this.loadData(); // Reload data to display the newly created note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se uložit novinku. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    editNote: function () {
      this.$store.getters.api.put("/news", { title: this.title, message: this.message, id: this.id }).then(function (response) {
        this.loadData(); // Reload data to display the edited note
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se uložit novinku. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    loadData: function () {
      this.$store.getters.api.get("/news").then(function (response) {
        this.news = response.data.items;
      }.bind(this));

      this.$store.getters.api.get("/member/" + this.$store.getters.user.id + "/functions").then(function (response) {
        this.functionsCurrent = response.data.current_roles.sort((a, b) => {
          return this.parseCustomDate(b.created_at) - this.parseCustomDate(a.created_at);
        });
        this.getQrCode();
      }.bind(this));

      if (this.$store.getters.hasPerm('view_person') && this.$store.getters.hasPerm('view_personfunction') && this.$store.getters.user.krp_region_id) {
        this.$store.getters.api.get("/stats/krp/" + this.$store.getters.user.krp_region_id).then(function (response) {
          this.stats_krp = response.data.region;
        }.bind(this));
      }

      if (this.$store.getters.hasPerm('view_person') && this.$store.getters.hasPerm('view_personfunction')) {
        this.$store.getters.api.get("/stats/rep").then(function (response) {
          this.stats_rep = response.data.items;
        }.bind(this));
      }

      if (this.$store.getters.hasPerm('view_person') && this.$store.getters.hasPerm('view_personfunction')) {
        this.$store.getters.api.get("/stats/office").then(function (response) {
          this.stats_office = response.data.items;
        }.bind(this));
      }

      if (this.$store.getters.user.domestic_region && this.$store.getters.user.domestic_region.id) {
        let branchId = 0;
        if (this.$store.getters.user.domestic_branch && this.$store.getters.user.domestic_branch.id) {
          branchId = this.$store.getters.user.domestic_branch.id;
        }
        this.$store.getters.api.get("/stats/myregion/" + this.$store.getters.user.domestic_region.id + "/branch/" + branchId).then(function (response) {
          this.my_region = response.data.region;
        }.bind(this));
      }

      if (this.$store.getters.hasPerm('view_budget')) {
        const countries = this.$store.getters.countries;
        const regions = this.$store.getters.regions;
        const orgAll = countries.concat(regions);
        let krp_id = null;
        if (this.$store.getters.user.krp_region_id) {
          krp_id = this.$store.getters.user.krp_region_id;
        } else if (this.$store.getters.user.domestic_region && this.$store.getters.user.domestic_region.id) {
          krp_id = this.$store.getters.user.domestic_region.id;
        }
        const region_ids = []
        let main_region = null;
        if (krp_id) {
          main_region = krp_id;
        }
        if (this.$store.getters.hasPerm('manage_all_regions')) {
          main_region = 100;
        }
        orgAll.forEach((item) => {
          if ((krp_id && krp_id === item.id) || this.$store.getters.hasPerm('manage_all_regions')) {
            region_ids.push(item.id);
          }
        });
        const data = {
          params: {
            "regions": region_ids
          }
        };
        this.$store.getters.api.get("/finance/stats/budgets", data).then(function (response) {
          this.budgets = response.data.items;
        }.bind(this));

        if (this.$store.getters.hasPerm('view_finances')) {
          this.$store.getters.api.get("/finance/stats/chapters", { "params": { "region": main_region } }).then(function (response) {
            this.chapters = response.data.items;
          }.bind(this));
        }
      }

      if (this.$store.getters.hasPerm('view_finances')) {
        this.$store.getters.api.get("/finance/stats/unapproved").then(function (response) {
          this.unapproved_finances = response.data.items;
        }.bind(this));
      }
    },
    parseCustomDate(dateString) {
      const [day, month, year] = dateString.split('.').map(Number);
      return new Date(year, month - 1, day);
    },
    interviewRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const allRegions = this.$store.getters.regions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství") {
            memberFunction.push(func.id);
          }
        });
        const memberRegion = [];
        allRegions.forEach((reg) => {
          if (reg.name === this.stats_krp.region) {
            memberRegion.push(reg.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$store.commit("setFiltersRegister", { key: "domestic_regions", value: memberRegion });
        this.$router.push({ name: 'members' });
      }
    },
    interviewAllRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství") {
            memberFunction.push(func.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$router.push({ name: 'members' });
      }
    },
    toApproveRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const allRegions = this.$store.getters.regions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství - pohovor schválen") {
            memberFunction.push(func.id);
          }
        });
        const memberRegion = [];
        allRegions.forEach((reg) => {
          if (reg.name === this.stats_krp.region) {
            memberRegion.push(reg.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$store.commit("setFiltersRegister", { key: "domestic_regions", value: memberRegion });
        this.$router.push({ name: 'members' });
      }
    },
    toApproveAllRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství - pohovor schválen") {
            memberFunction.push(func.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$router.push({ name: 'members' });
      }
    },
    approvedRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const allRegions = this.$store.getters.regions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství - KRP schválen") {
            memberFunction.push(func.id);
          }
        });
        const memberRegion = [];
        allRegions.forEach((reg) => {
          if (reg.name === this.stats_krp.region) {
            memberRegion.push(reg.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$store.commit("setFiltersRegister", { key: "domestic_regions", value: memberRegion });
        this.$store.commit("setFiltersRegister", { key: "member_status", value: "unpaid" });
        this.$router.push({ name: 'members' });
      }
    },
    approvedAllRedirect: function () {
      if (this.stats_krp.region && this.stats_krp.region !== "") {
        const allFunctions = this.$store.getters.positions;
        const memberFunction = [];
        allFunctions.forEach((func) => {
          if (func.czech_name === "Zájemce o členství - KRP schválen") {
            memberFunction.push(func.id);
          }
        });
        this.$store.commit("resetFiltersRegister");
        this.$store.commit("setFiltersRegister", { key: "member_types", value: memberFunction });
        this.$store.commit("setFiltersRegister", { key: "member_status", value: "unpaid" });
        this.$router.push({ name: 'members' });
      }
    },
    financesToApproveRedirect: function () {
      const krp_region_id = this.$store.getters.user.krp_region_id;
      if (this.$store.getters.hasPerm('manage_all_regions') && !(this.selectedView.name === 'Krajský předseda' || this.selectedView.name === 'Krajský místopředseda')) {
        this.$store.commit("resetFiltersFinance");
        this.$store.commit("setFiltersFinance", { key: "document_state", value: ["unapproved"] });
        this.$router.push({ name: 'finances' });
      }
      else if (krp_region_id) {
        this.$store.commit("resetFiltersFinance");
        this.$store.commit("setFiltersFinance", { key: "region", value: [krp_region_id] });
        this.$store.commit("setFiltersFinance", { key: "document_state", value: ["unapproved"] });
        this.$router.push({ name: 'finances' });
      }
    },
    getQrCode() {
      if (this.$store.getters.user) {
        const contribution = this.$store.getters.user.contribution;
        const vs = this.$store.getters.user.id;
        let membershipPaymentAccount = "";
        let membershipPaymentMessage = "";
        const membershipPaymentBank = "2010"
        if (contribution) {
          this.functionsCurrent.forEach((item) => {
            if (item.czech_name === "Člen" || item.czech_name === "Zájemce o členství - KRP schválen" || item.czech_name === "Nezaplacený Člen") {
              membershipPaymentAccount = "2100382818";
              membershipPaymentMessage = `clensky prispevek (${this.$store.getters.user.name})`;
            }
            if (item.czech_name === "Příznivec" || item.czech_name === "Nezaplacený Příznivec" || item.czech_name === "Ukončené příznivectví" || item.czech_name === "Nový Příznivec") {
              membershipPaymentAccount = "7505075050";
              membershipPaymentMessage = `dar priznivce (${this.$store.getters.user.name})`;
            }
          });
        }
        if (membershipPaymentAccount) {
          this.qrCodeBasic = "https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=" + membershipPaymentAccount
            + "&bankCode=" + membershipPaymentBank
            + "&amount=" + contribution
            + ".00&currency=CZK&vs=" + vs
            + "&message=" + membershipPaymentMessage + "&size=200";

          this.qrCodeRecommended = "https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=" + membershipPaymentAccount
            + "&bankCode=" + membershipPaymentBank
            + "&amount=" + CONFIG.recommendedPayment
            + ".00&currency=CZK&vs=" + vs
            + "&message=" + membershipPaymentMessage + "&size=200";
        } else {
          this.qrCodeBasic = "";
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
.bg-primary {
  background-color: $background-green !important;
  border-color: $green-light;
}

.btn-outline-primary {
  border-color: $green-dark;
  color: $green-dark;
}

.btn-outline-primary:hover {
  border-color: $green-light;
  color: $green-light;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: $green-dark;
  border-color: $green-dark;
  color: #fff;
}

ul {
  text-align: left;
}

.btn-primary {
  background-color: $yellow-light;
  border-color: $yellow-main;
  color: black;
}

.btn-primary:hover {
  background-color: $yellow-main;
  border-color: $yellow-main;
  color: black;
}

.btn-primary:active,
.btn-primary:target,
.btn-primary:focus,
.dropdown-item:active,
.dropdown-item:target,
.dropdown-item:focus,
.btn.show {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
  color: black !important;
}

.qrcode {
  padding: 0px;
  max-width: 232px;
}
</style>
