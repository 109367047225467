<template>
  <MemberFilter
  :settings="settings"
  :filters="this.$store.getters.filtersRegister"
  @changeFilters="changeFilters"
  @saveView="saveView"
  @editView="editView"
  @deleteView="deleteView"
  @exportCSV="exportCSV"
  @exportXLSX="exportXLSX"
  @exportPDF="exportPDF"
  @showAdvanced="showColumnSelect"
  @reloadData="loadData"
  />
  <transition name="fade">
    <template v-if="showingColumnSelect">
      <ColumnFilter
      :settings="settings"
      :selectedColumns="selectedColumns"
      @changeColumns="changeColumns"
      class="px-3"
      />
    </template>
  </transition>
  <Table
  :settings="settings"
  :items="items"
  :columns="selectedColumns"
  @changeSort="changeSort"
  @selectAll="selectAll"
  @deselectAll="deselectAll"
  @selectChangeRow="selectChangeRow"
  />
  <Pagination
  :totalCount="settings.totalCount"
  :perPage="settings.perPage"
  :currentPage="settings.currentPage"
  @page-changed="updatePageData"
  @update:per-page="updatePerPage"
  />
  <LoadingSpinner :csvLoading="csvLoading"/>
</template>

<script>
import Table from "@/components/Table.vue";
import MemberFilter from "@/components/MemberFilter.vue";
import CheckboxComponent from '@/components/CheckboxComponent.vue';
import ColumnFilter from '@/components/ColumnFilter.vue';
import Pagination from "@/components/Pagination.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import axios from "axios";
import { markRaw } from "vue";

export default {
  created: function () {
    this.setDefaultColumns();
    this.settings.perPage = this.$store.getters.filtersRegister.per_page;
    this.settings.sortBy = this.$store.getters.filtersRegister.sort_by;
    this.settings.sortDirection = this.$store.getters.filtersRegister.sort_order;
    this.loadData(this.$store.getters.filtersRegister.current_page);
  },
  components: {
    Table,
    MemberFilter,
    CheckboxComponent,
    ColumnFilter,
    Pagination,
    LoadingSpinner,
  },
  data() {
    return {
      listRequestSource: null,
      showingColumnSelect: false,
      csvLoading: false,
      items: [],
      selectedColumns: [],
      settings: {
        endpoint: "/people",
        sortBy: "id",
        sortDirection: "D",
        settingsType: "members",
        loading: false,
        totalCount: 0,
        perPage: 50,
        currentPage: 1,
        selected: [],
        columns: [
          {
            col: 1,
            name: "id",
            userFriendly: "ID",
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "last_name",
            userFriendly: "Jméno",
            cellFunction: (item) => {return item.name},
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "member_type",
            userFriendly: "Funkce",
            default: "-",
            sortable: false,
            defaultShow: true
          },
          {
            col: 2,
            name: "domestic_region",
            userFriendly: "Kraj",
            default: "-",
            cellFunction: (item) => {return item.domestic_region.name},
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "domestic_branch",
            // defaultValue: "-",
            userFriendly: "Pobočka",
            default: "-",
            cellFunction: (item) => {return item.domestic_branch.name},
            sortable: true,
            defaultShow: false
          },
          {
            col: 2,
            name: "paid",
            userFriendly: "Stav",
            cellFunction: this.paid,
            sortable: false,
            defaultShow: false
          },
          {
            col: 3,
            name: "email",
            userFriendly: "Email",
            default: "-",
            cellFunction: (item) => {return item.user.email},
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "phone",
            userFriendly: "Telefon",
            default: "-",
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "city",
            // defaultValue: "-",
            userFriendly: "Obec",
            default: "-",
            cellFunction: (item) => {return item.domestic_address.city},
            sortable: true,
            defaultShow: false
          },
          {
            col: 1,
            name: "zip",
            // defaultValue: "-",
            userFriendly: "PSČ",
            default: "-",
            cellFunction: (item) => {return item.domestic_address.zip},
            sortable: true,
            defaultShow: false
          },
        ],
        row: {
          isClickable: this.isClickable,
          name: 'member-detail',
          redirect: this.redirect
        }
      }
    }
  },
  methods: {
    paid: function(item) {
      if (!item.paid_till) {
        return 'Nezaplaceno';
      }
      const currentDate = new Date();
      const paidTillDate = new Date(item.paid_till);
      // Set the time of both dates to midnight to only compare the date part
      currentDate.setHours(0, 0, 0, 0);
      paidTillDate.setHours(0, 0, 0, 0);
      // Zaplaceno if paidTillDate is greater than or equal to currentDate
      return paidTillDate >= currentDate ? 'Zaplaceno' : 'Nezaplaceno';
    },
    branchName: function (item) {
      if (!item.domestic_branch_id) {
        return "-";
      }
      const branch = this.$store.getters.branches.find(branch => branch.id === item.domestic_branch_id);
      return branch ? branch.name : item.domestic_branch_id;
    },
    regionName: function (item) {
      if (!item.domestic_region_id) {
        return "-";
      }
      const region = this.$store.getters.regions.find(region => region.id === item.domestic_region_id);
      return region ? region.name : item.domestic_region_id;
    },
    isClickable: function (item) {
      return true;
    },
    redirect: function (item) {
      this.$router.push({ name: 'member-detail', params: { id: item.id } });
    },
    setDefaultColumns() {
      this.selectedColumns = [];
      if (this.$store.getters.filtersRegister.columns.length > 0) {
        const filteredColumns = this.settings.columns.filter((value) =>{
          return this.$store.getters.filtersRegister.columns.includes(value.name)
        });
        this.selectedColumns = filteredColumns;
      } else {
        for (let i = 0; i < this.settings.columns.length; i++) {
          if (this.settings.columns[i].defaultShow) {
            this.selectedColumns.push(this.settings.columns[i])
          }
        }
      }
    },
    loadData: function (page) {
      // setup
      this.settings.loading = true;

      // cancel last request and create new source
      if (this.listRequestSource !== null) {
        this.listRequestSource.cancel('Operace zrušena uživatelem.');
      }
      this.listRequestSource = axios.CancelToken.source();

      if (page) {
        this.settings.currentPage = page;
      }

      // Send only the data we need on backend
      let filtersCopy = {...this.$store.getters.filtersRegister};
      delete filtersCopy.columns;
      const data = {
        cancelToken: this.listRequestSource.token,
        params: {...filtersCopy}
      };
      this.$store.getters.api.get(this.settings.endpoint, data).then(function (response) {
        const resData = response.data;
        this.items = resData.items;
        this.settings.totalCount = resData._total;

        // cancel loading and remove request source
        this.settings.loading = false;
        this.listRequestSource = null;
      }.bind(this))
      .catch((e) => {
          if (axios.isCancel(e)) {
              console.log("Request canceled", e.message);
          } else {
              console.log(e);
          }
          this.requestRunning = false;
      });
    },
    exportCSV: function (name) {
      this.csvLoading = true;
      const data = {
        params: {
          selected: JSON.stringify(this.settings.selected),
          ...this.$store.getters.filtersRegister
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get(this.settings.endpoint + "/csv", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.csv');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    exportXLSX: function (name) {
      this.csvLoading = true;
      const data = {
        params: {
          selected: JSON.stringify(this.settings.selected),
          ...this.$store.getters.filtersRegister
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get(this.settings.endpoint + "/xlsx", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    exportPDF: function (name) {
      this.csvLoading = true;
      const data = {
        params: {
          filename: name,
          selected: JSON.stringify(this.settings.selected),
          ...this.$store.getters.filtersRegister
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get(this.settings.endpoint + "/pdf", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.pdf');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    changeFilters: function (key, val) {
      this.$store.commit("setFiltersRegister", { key, value: val });
      this.$store.commit("setFiltersRegister", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      if (key === "per_page") {
        this.settings.perPage = val;
      }
      if (key === "sort_by") {
        this.settings.sortBy = val;
      }
      if (key === "sort_order") {
        this.settings.sortDirection = val;
      }
      if (key === "columns") {
        if (val.length > 0) {
          const filteredColumns = this.settings.columns.filter((value) =>{
            return val.includes(value.name)
          });
          this.selectedColumns = filteredColumns;
        } else {
          this.setDefaultColumns();
        }
      }
    },
    changeColumns: function (columns) {
      const selectedColNames = [];
      columns.forEach((column) => {
        selectedColNames.push(column.name);
      });
      this.$store.commit("setFiltersRegister", { key: "columns", value: selectedColNames });
      this.selectedColumns = columns;
    },
    changeSort: function (column, direction) {
      this.$store.commit("setFiltersRegister", { key: "sort_by", value: column });
      this.settings.sortBy = column;
      this.$store.commit("setFiltersRegister", { key: "sort_order", value: direction });
      this.settings.sortDirection = direction;
      this.$store.commit("setFiltersRegister", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      this.loadData();
    },
    selectChangeRow: function (item_id, state) {
      if (state) {
        this.settings.selected.push(item_id)
      }
      else {
        const index = this.settings.selected.indexOf(item_id);
        this.settings.selected.splice(index, 1);
      }
    },
    selectAll: function () {
      this.deselectAll();
      for (let i = 0; i < this.items.length; i++) {
        this.settings.selected.push(this.items[i].id)
      }
    },
    deselectAll: function () {
      this.settings.selected = [];
    },
    updatePageData(page) {
      this.$store.commit("setFiltersRegister", { key: "current_page", value: page });
      this.settings.currentPage = page;
      this.loadData();
    },
    updatePerPage(newPerPage) {
      this.$store.commit("setFiltersRegister", { key: "per_page", value: newPerPage });
      this.settings.perPage = newPerPage;
      this.$store.commit("setFiltersRegister", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      this.loadData();
    },
    saveView: function (name) {
      const userId = this.$store.getters.user.user.id;
      const view = this.buildViewData();
      view.name = name;
      this.$store.getters.api.post("/user/" + userId + "/settings", view).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    editView: function (id) {
      const userId = this.$store.getters.user.user.id;
      const view = this.buildViewData();
      const oldView = this.$store.getters.settings.find((view) => view.id == id);
      view.id = id;
      view.name = oldView.name;
      this.$store.getters.api.put("/user/" + userId + "/settings", view).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    deleteView: function (id) {
      const userId = this.$store.getters.user.user.id;
      this.$store.getters.api.delete("/user/" + userId + "/settings/" + id).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    buildViewData: function () {
      const selectedColNames = [];
      this.selectedColumns.forEach((column) => {
        selectedColNames.push(column.name);
      });
      this.$store.commit("setFiltersRegister", { key: 'columns', value: selectedColNames });
      this.$store.commit("setFiltersRegister", { key: 'per_page', value: this.settings.perPage });
      this.$store.commit("setFiltersRegister", { key: 'sort_by', value: this.settings.sortBy });
      this.$store.commit("setFiltersRegister", { key: 'sort_order', value: this.settings.sortDirection });
      const view = {
        id: null,
        name: null,
        settings_type: this.settings.settingsType,
        group: null,
        data: this.$store.getters.filtersRegister
      };
      return view;
    },
    showColumnSelect: function () {
      this.showingColumnSelect = !this.showingColumnSelect;
    },
  }
}
</script>

<style scoped lang="scss">
</style>
