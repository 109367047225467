<template>
  <div class="row pb-3">
    <div class="col-auto">
      <button v-if="canEdit && (!this.document.approved_at || !this.document.pay_from.id || this.document.payment_error)" class="btn btn-primary text-nowrap me-2" @click="editData(true)">
        <icon icon="Edit" />
        &nbsp;{{ editable ? "Uložit změny" : "Editovat údaje" }}
      </button>
      <button v-if="editable" class="btn btn-secondary text-nowrap" @click="editData(false)">
        Zavřít bez uložení
      </button>
    </div>
    <div class="col-auto ms-auto">
      <button class="btn btn-sm"
        :class="(approvedDocument === 'Schválit' || approvedDocument === 'Schválit import') ? 'btn-light' : 'btn-success'"
        :disabled="this.document.approved_at || disabledKRP"
        @click="approveDocument"
        v-if="!editable
          && (this.document.paired_bank_at || (this.document.document_type && this.document.document_type.financial_type === 'V'))
          && !this.document.rejected_at && $store.getters.hasPerm('approve_finances')">
        {{ approvedDocument }}
      </button>
      <button class="btn btn-sm ms-2 btn-danger"
        :disabled="disabledKRP || this.document.rejected_at"
        @click="rejectDocument"
        v-if="(!this.document.approved_at && !this.document.paired_bank_at
          && (this.document.document_type && this.document.document_type.financial_type === 'V')) && $store.getters.hasPerm('approve_finances')">
        {{ this.document.rejected_at ? `Zamítl ${this.document.rejected_by.name}` : "Zamítnout" }}
      </button>
      <div class="float-end" v-if="!editable
          && (this.document.paired_bank_at || (this.document.document_type && this.document.document_type.financial_type === 'V' && this.document.approved_at))
          && $store.getters.hasPerm('pay_finances')">
        <button class="btn btn-sm ms-2" :class="getPaidClass()"
          :disabled="payButtonDisabled"
          @click="payDocument"
        >
          {{ paidDocument }}
        </button>
      </div>
      <button class="btn btn-sm ms-2 btn-secondary"
        data-bs-toggle="modal"
        data-bs-target="#return"
        v-if="this.document.paired_bank_at && $store.getters.hasPerm('refund_payment') && !document.already_paid_document"
        @click="setDefault()"
        :disabled="this.document.returned_at">
        {{ this.document.returned_at ? `Platbu vrátil ${this.document.returned_by.name}` : "Vrátit platbu" }}
      </button>
    </div>
  </div>
  <Popup
    :header="'Vrátit platbu'"
    :editable="true"
    :button="'Vrátit platbu'"
    id="return"
    @save-data="returnPayment"
    v-if="this.document.paired_bank_at && $store.getters.hasPerm('refund_payment')">
    <template v-slot:editor>
      <div class="mb-3">
        <label for="amount" class="m-2">Částka:</label>
        <input id="amount" type="number" class="form-control my-2" v-model="amount">
        <label for="vs" class="m-2">Variabilní symbol:</label>
        <input id="vs" type="text" class="form-control my-2" v-model="vs">
        <label for="accountFrom" class="m-2">
          {{ this.document.document_type && this.document.document_type.financial_type === "P" ? 'Zaplatit z účtu:' : 'Očekávat vrácení na účet:' }}
        </label>
        <select id="accountFrom" class="form-select my-2" v-model="accountFrom">
          <option v-for="account in this.bankAccounts" :key="account.id" :value="account.id">
            {{ (account.name ? account.name + ' ' : '') + '(' + bankAccount(account) + ')' }}
          </option>
        </select>
        <template v-if="this.document.document_type && this.document.document_type.financial_type === 'P'">
          <label for="accountToNumber" class="m-2">Zaplatit na účet:</label>
          <div class="d-flex align-items-center">
            <input id="accountToPrefix" type="text" class="form-control my-2 w-75" v-model="accountToPrefix"><span>&nbsp;-&nbsp;</span>
            <input id="accountToNumber" type="text" class="form-control my-2" v-model="accountToNumber"><span>&nbsp;/&nbsp;</span>
            <input id="accountToBank" type="text" class="form-control my-2 w-50" v-model="accountToBank">
          </div>
        </template>
        <label for="description" class="m-2">Poznámka:</label>
        <input id="description" type="text" class="form-control my-2" v-model="description">
      </div>
    </template>
  </Popup>
  <Popup
      :header="'Evidování platby'"
      :editable="true"
      id="addPayment"
      :button="'Uložit'"
      @save-data="alreadyPaidDocument"
      v-if="$store.getters.hasPerm('view_documents')">
      <template v-slot:editor>
        <label class="my-2">Datum:</label>
        <input type="datetime-local" class="form-control" v-model="paymentPaidDatetime">
        <label class="my-2">Poznámka:</label>
        <textarea class="form-control" v-model="paymentNote"></textarea>
      </template>
    </Popup>
</template>


<script>
import Popup from '@/components/Popup.vue';

export default {
  components: {
    Popup
  },
  emits: ['edit-data', 'load-data'],
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    documentId: {
      required: true
    },
    document: {
      type: Object,
      required: true
    },
    bankAccounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      amount: 0,
      vs: "",
      description: "",
      accountFrom: "",
      accountToPrefix: "",
      accountToNumber: "",
      accountToBank: "",
      paymentNote: "",
      paymentPaidDatetime: null
    }
  },
  computed: {
    payButtonDisabled: function () {
      return !this.document.pay_from.id
        || !this.document.pay_to.id
        || this.document.payment_error
        || this.approvedDocument === 'Schválit'
        || this.paidDocument === 'Zaplaceno'
        || this.paidDocument === 'Autorizace'
        || this.paidDocument === 'Odesláno';
    },
    canEdit: function () {
      return this.$store.getters.hasPerm('change_finances') && !this.disabledKRP;
    },
    disabledKRP: function() {
      if (this.$store.getters.hasPerm('manage_all_regions')) {
        return false;
      }
      if (this.$store.getters.user.krp_region_id && this.document && this.document.region && !this.document.region.id) {
        return true;
      }
      if (this.$store.getters.user.krp_region_id && this.document && this.document.region && this.document.region.id) {
        return !(this.$store.getters.user.krp_region_id === this.document.region.id);
      }
      return false;
    },
    approvedDocument: function () {
      if (this.document.approved_at) {
        return this.document.approved_by ? `Schválil ${this.document.approved_by.name}` : "Schváleno";
      }
      if (this.document.paired_bank_at) {
        return "Schválit import";
      }
      return "Schválit"
    },
    paidDocument: function() {
      if (this.document.paired_bank_at) {
        return "Zaplaceno"
      }
      if (this.document.payment_error) {
        return "Chyba"
      }
      if (this.document.exported_bank_at) {
        return "Autorizace";
      }
      if (this.document.paid_at) {
        return "Odesláno";
      }
      return "Zaplatit"
    },
  },
  methods: {
    editData(save) {
      this.$emit('edit-data', save);
    },
    approveDocument: function () {
      this.$store.getters.api.put('/finance/approve', {ids: [this.document.id]}).then(function (response) {
        const resData = response.data;
        if (resData.items.length > 0) {
          this.document.approved_at = resData.items[0].approved_at;
        }
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    rejectDocument: function () {
      this.$store.getters.api.put('/finance/reject', {ids: [this.document.id]}).then(function (response) {
        const resData = response.data;
        if (resData.items.length > 0) {
          this.document.rejected_at = resData.items[0].rejected_at;
          this.document.rejected_by = resData.items[0].rejected_by;
        }
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    payDocument: function () {
      this.$store.getters.api.put('/finance/pay', {ids: [this.document.id]}).then(function (response) {
        const resData = response.data;
        this.document.paid_at = resData.items[0].paid_at;
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    alreadyPaidDocument: function () {
      this.$store.getters.api.put('/finance/paid', {
        ids: [this.document.id],
        datetime: this.paymentPaidDatetime,
        note: this.paymentNote
      }).then(function (response) {
        setTimeout(function () {
          this.$emit('load-data');
        }.bind(this), 500);
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    getPaidClass() {
      return this.document.paired_bank_at
        ? 'btn-success'
        : this.document.payment_error
        ? 'btn-danger'
        : !this.document.paid_at
        ? 'btn-light'
        : 'btn-secondary';
    },
    returnPayment() {
      if (!this.amount) {
        alert("Musíte zadat částku");
        return;
      }
      const numberPattern = /^\d*$/; // regex pattern for numbers only
      if (this.vs && !numberPattern.test(this.vs)) {
        alert('VS musí obsahovat číselnou hodnotu');
        return
      }
      if ((this.accountToPrefix && !numberPattern.test(this.accountToPrefix))
          || (this.accountToNumber && !numberPattern.test(this.accountToNumber))
          || (this.accountToBank && !numberPattern.test(this.accountToBank))) {
        alert('Číslo bankovního účtu musí obsahovat číselnou hodnotu');
        return
      }
      const data = {
        amount: this.amount,
        vs: this.vs,
        description: this.description,
        account_from: this.accountFrom,
        account_prefix: this.accountToPrefix,
        account_number: this.accountToNumber,
        account_bank: this.accountToBank,
        document_id: parseInt(this.documentId),
      }
      this.$store.getters.api.post("/finance/return", data)
        .then(function (response) {
          this.document.returned_at = response.data.items.created_at;
          this.document.returned_by = response.data.items.created_by;
          this.document.returned_document = response.data.items.id;
        }.bind(this))
        .catch(function (error) {
          alert("Nepodařilo se vrátit platbu. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
        });
    },
    bankAccount(account) {
      let accountString = "";
      if (account && account.account_prefix) {
        accountString += account.account_prefix + "-";
      }
      if (account && account.account_number && account.bank_number) {
        accountString += account.account_number + " / " + account.bank_number;
      } else if (account && account.iban) {
        accountString = account.iban;
        if (account.bic) {
          accountString += ", " + account.bic;
        }
      }
      return accountString;
    },
    setDefault() {
      if (this.document.document_type && this.document.document_type.financial_type === "V") {
        this.accountFrom = this.document.pay_from.id;
        this.accountToPrefix = "";
        this.accountToNumber = "";
        this.accountToBank = "";
      } else {
        this.accountFrom = this.document.pay_to.id;
        this.accountToPrefix = this.document.pay_from.account_prefix;
        this.accountToNumber = this.document.pay_from.account_number;
        this.accountToBank = this.document.pay_from.bank_number;
      }
      this.amount = this.document.amount;
      this.vs = this.document.vs;
      this.description = `Vrácení platby z ${this.document.paired_bank_at.split(" ")[0]} ve výši ${this.document.amount}`;
    }
  },
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
.btn-light {
  background-color: $yellow-light !important;
  border-color: $yellow-main !important;
}

.btn-light:hover {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
}

.btn-light:active,
.btn-light:target {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
}
</style>