<template>
  <div class="card-body">
    <h4 class="card-title">{{ title }}</h4>
    <p class="card-text text-muted">{{ description }}</p>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.card {
  margin: 20px auto;
}
</style>
