<template>
    <div class="d-flex">
        {{ name }}&nbsp;&nbsp;
        <template v-if="sortable">
            <div @click="$emit('sort', column, 'A')" class="cursor_pointer mx-1">
                <icon icon="ArrowUp" :class="isSelected('A')"/>
            </div>
            <div @click="$emit('sort', column, 'D')" class="cursor_pointer">
                <icon icon="ArrowDown" :class="isSelected('D')"/>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        column: {
            type: String,
            required: true
        },
        sortable: {
            type: Boolean,
            required: false,
            default: true
        },
        selected: {
            type: Object,
            required: false
        }
    },
    methods: {
        isSelected: function (type) {
            if (this.selected && this.selected.column === this.column && this.selected.direction == type) {
                return "selected_sort";
            }
            return "";
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/abstracts/_variables.scss";
.selected_sort {
    stroke-width: 2;
    stroke: $green-main;
}
</style>