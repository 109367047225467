<template>
  <div class="col-12">
    <div class="justify-content-between d-flex">
      <h2 class="w-100 d-flex justify-content-between align-items-center">
        <span>Zařazení do rozpočtu</span>
        <button type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addBudget"
          @click="resetBudget()"
          v-if="$store.getters.hasPerm('add_budget')">
          <icon icon="Plus" />
        </button>
      </h2>
    </div>
    <div class="pb-4">
      <DocumentDetailRow v-if="budgets.length === 0" header="Nezapočítává se do rozpočtu"
          :body="'-'"
        />
      <template v-if="$store.getters.hasPerm('view_budget')" v-for="budget in budgets">
        <DocumentDetailRow :header="`${budget.region.name}`"
          :body="`${budget.amount} Kč / ${budget.paid_at}`">
          <template v-slot:body>
            <div class="w-100 d-flex justify-content-between">
              <span>{{ budget.amount }} Kč / {{ budget.paid_at }}</span>
              <button v-if="$store.getters.hasPerm('delete_financesbudget')" class="btn btn-primary float-end" @click="removeBudget(budget.id)">X</button>
            </div>
          </template>
        </DocumentDetailRow>
      </template>
    </div>
  </div>
  <Popup ref="addBudget"
    :header="'Přidání rozpočtu'"
    :editable="true"
    id="addBudget"
    @save-data="addBudget"
    v-if="$store.getters.hasPerm('add_financesbudget')"
  >
    <template v-slot:editor>
        <label for="date" class="m-2">Org. složka:</label>
        <select class="form-select flex-fill w-100" v-model="budget.region">
          <option v-for="region in regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </option>
        </select>
        <label for="date" class="m-2">Datum:</label>
        <input id="date" type="date" class="form-control my-2" v-model="budget.date">
        <label for="price" class="m-2">Částka:</label>
        <input id="price" type="number" class="form-control my-2" v-model="budget.price">
    </template>
  </Popup>
</template>


<script>
import DocumentDetailRow from '@/components/DocumentDetailRow.vue';
import Popup from '@/components/Popup.vue';

export default {
  emits: ['reload-data'],
  components: {
    DocumentDetailRow,
    Popup
  },
  props: {
    documentId: {
      required: true
    },
    date: {
      required: false
    },
    totalAmount: {
      type: String,
      required: false,
      default: "0"
    }
  },
  data() {
    return {
      budgets: [],
      budget: {
        date: null,
        price: 0
      }
    }
  },
  created: function () {
    this.loadData();
  },
  computed: {
    regions() {
      const countries = this.$store.getters.countries;
      const regions = this.$store.getters.regions;
      const orgAll = countries.concat(regions);
      return orgAll;
    },
  },
  watch: {
    budgets: {
      handler() {
        this.priceCalculate()
      },
      deep: true
    },
    totalAmount: {
      handler() {
        this.priceCalculate()
      },
      deep: true
    },
    date: function () {
      this.budget.date = this.dates;
    }
  },
  methods: {
    resetBudget: function () {
      this.budget = {
        date: this.date
      };
      this.priceCalculate()
    },
    loadData: function () {
      this.$store.getters.api.get("/finance/" + this.documentId + "/budgets").then(function (response) {
        this.budgets = response.data.items;
      }.bind(this));
    },
    priceCalculate: function () {
        var amount = 0;
        var totalAmountNumber = parseFloat(this.totalAmount);
        for (var i = 0; i < this.budgets.length; i++){
          amount += parseFloat(this.budgets[i].amount);
        }
        this.budget.price = totalAmountNumber - amount;
    },
    addBudget: function () {
      this.$store.getters.api.post("/finance/" + this.documentId + "/budget/add", this.budget).then(function (response) {
        this.budgets = response.data.items;
      }.bind(this));
    },
    removeBudget: function (budgetId) {
      this.$store.getters.api.delete("/finance/" + this.documentId + "/budget/" + budgetId + "/delete").then(function (response) {
        this.budgets = response.data.items;
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">
h2 {
  font-size: 24px;
}

.row {
    border-bottom: 1px solid #ccc;
  }
</style>