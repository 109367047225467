<template>
  <div>
    <div class="justify-content-between d-flex">
      <h2>Dárce</h2>
      <div>
        <button type="button"
          class="btn btn-primary me-2"
          @click="donorContract()"
          v-if="$store.getters.hasPerm('generate_donations') && donor">
          Smlouva
        </button>
        <button type="button"
          class="btn btn-primary me-2"
          @click="donorConfirmation()"
          v-if="$store.getters.hasPerm('generate_donations') && donor">
          Potvrzení
        </button>
        <button type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#updateDonor"
          @click="updateDonor()"
          v-if="$store.getters.hasPerm('change_donor') || $store.getters.hasPerm('add_donor')">
          Upravit údaje
        </button>
      </div>
    </div>
    <div>
      <div v-if="donor === null" class="my-2">Žádná data o dárci</div>
      <div v-else>
        <DocumentDetailRow>
          <template v-slot:header>
              <span v-if="donor.company">Název</span>
              <span v-else>Jméno</span>
          </template>
          <template v-slot:body>
            <span v-if="donor.company">{{ donor.company_name }}</span>
            <span v-else>
              <span v-if="donor.name_prefix">{{ donor.name_prefix }} </span>
              <span>{{ donor.first_name }} {{ donor.last_name }}</span>
              <span v-if="donor.name_suffix"> {{ donor.name_suffix }}</span>
            </span>
          </template>
        </DocumentDetailRow>
        <DocumentDetailRow>
          <template v-slot:header>
              <span v-if="donor.company">Sídlo</span>
              <span v-else>Trvalé bydliště</span>
          </template>
          <template v-slot:body>
            <span>{{ donor.address }}</span>
          </template>
        </DocumentDetailRow>
        <DocumentDetailRow v-if="donor.company">
          <template v-slot:header>
              <span>IČO</span>
          </template>
          <template v-slot:body>
            <span>{{ donor.organization_id }}</span>
          </template>
        </DocumentDetailRow>
        <DocumentDetailRow v-if="!donor.company">
          <template v-slot:header>
              <span>Datum narození</span>
          </template>
          <template v-slot:body>
            <span>{{ donor.birth_date }}</span>
          </template>
        </DocumentDetailRow>
      </div>
    </div>
  </div>
  <Popup ref="updateDonor"
    :header="'Změna dárce'"
    :editable="true"
    id="updateDonor"
    @save-data="updateDonor"
    v-if="$store.getters.hasPerm('change_donor') || $store.getters.hasPerm('add_donor')"
  >
    <template v-slot:editor>
      <div class="mb-3">
        <div class="row align-items-center justify-content-center mb-4">
          <div class="col-4">
            <span>Fyzická osoba</span>
          </div>
          <div class="col-4">
            <label class="switch">
              <input type="checkbox" v-model="donorUpdated.company" :true-value="true" :false-value="false">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="col-4">
            <span>Právnická osoba</span>
          </div>
        </div>
        <div v-if="donorUpdated.company">
          <label for="name" class="m-2">Název firmy (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.company_name">
          <label for="name" class="m-2">Sídlo (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.address">
          <label for="name" class="m-2">Obec (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.city">
          <label for="name" class="m-2">IČO (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.organization_id">
        </div>
        <div v-else>
          <label for="name" class="m-2">ID osoby:</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.person_id">
          <button class="btn btn-primary" @click="loadPersonalData()" :disabled="!this.donorUpdated.person_id">
            Načíst data z registru
          </button><br>
          <label for="name" class="m-2">Tituly před:</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.name_prefix">
          <label for="name" class="m-2">Křestní jméno (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.first_name">
          <label for="name" class="m-2">Příjmení (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.last_name">
          <label for="name" class="m-2">Tituly za:</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.name_suffix">
          <label for="name" class="m-2">Trvalé bydliště (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.address">
          <label for="name" class="m-2">Obec (povinné):</label>
          <input id="name" type="text" class="form-control my-2" v-model="donorUpdated.city">
          <label for="name" class="m-2">Datum narození (povinné):</label>
          <input id="name" type="date" class="form-control my-2" v-model="donorUpdated.birth_date">
        </div>
      </div>
    </template>
  </Popup>
</template>


<script>
import Popup from '@/components/Popup.vue';
import DocumentDetailRow from '@/components/DocumentDetailRow.vue';

export default {
  emits: ['reload-data'],
  components: {
    Popup,
    DocumentDetailRow
  },
  props: {
    documentId: {
      required: true
    }
  },
  data() {
    return {
      donor: null,
      donorUpdated: {}
    }
  },
  created: function () {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.$store.getters.api.get("/finance/" + this.documentId + "/donor").then(function (response) {
        this.donor = response.data.donor;
        this.donorUpdated = response.data.donor;
      }.bind(this));
    },
    loadPersonalData: function () {
      if (this.donorUpdated.person_id) {
        this.$store.getters.api.get("/member/" + this.donorUpdated.person_id + "/donor").then(function (response) {
          this.donorUpdated.first_name = response.data.first_name;
          this.donorUpdated.last_name = response.data.last_name;
          this.donorUpdated.name_prefix = response.data.name_prefix;
          this.donorUpdated.name_suffix = response.data.name_suffix;
          this.donorUpdated.address = response.data.address;
          this.donorUpdated.city = response.data.city;
          this.donorUpdated.birth_date = response.data.birth_date;
        }.bind(this)).catch(function (error) {
          alert("Data se nepodařilo načíst")
        });
      }
    },
    updateDonor: function () {
      if (this.donor) {
        this.$store.getters.api.put("/finance/" + this.documentId + "/donor", this.donorUpdated).then(function (response) {
          this.loadData();
        }.bind(this));
      }
      else {
        this.$store.getters.api.post("/finance/" + this.documentId + "/donor", this.donorUpdated).then(function (response) {
          this.loadData();
        }.bind(this));
      }
    },
    donorConfirmation: function () {
      if (this.donor) {
        this.$store.getters.api.get("/finance/" + this.documentId + "/donor/confirmation", {
          responseType: 'blob'
        }).then(function (response) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', this.documentId + " - potvrzení daru.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }.bind(this)).catch((error) => {
          console.log(error)
          alert("Smlouvu se nepodařilo vygenerovat.")
        });
      }
    },
    donorContract: function () {
      if (this.donor) {
        this.$store.getters.api.get("/finance/" + this.documentId + "/donor/contract", {
          responseType: 'blob'
        }).then(function (response) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', this.documentId + " - smlouva.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }.bind(this)).catch((error) => {
          alert("Smlouvu se nepodařilo vygenerovat.")
        });
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

h2 {
  font-size: 24px;
}

.row {
    border-bottom: 1px solid #ccc;
  }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $green-main;
}

input:focus + .slider {
  box-shadow: 0 0 1px $green-main;
}

input:checked + .slider:before {
  -webkit-transform: translateX(66px);
  -ms-transform: translateX(66px);
  transform: translateX(66px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>