<template>
  <div class="card-body">
    <h4 class="card-title">{{ title }}</h4>
    <p class="card-text text-muted">{{ description }}</p>
    <router-link class="btn btn-light" :to="{ name: link }">
      <icon :icon="icon" />
      {{ userFriendly }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    userFriendly: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.card {
  margin: 20px auto;
}

.btn {
  background-color: $yellow-light;
  border-color: $yellow-main;
  font-weight: bold;
}

.btn:hover {
  background-color: $yellow-main;
  border-color: $yellow-main;
}
</style>
