<template>
  <div class="card bg-light">
    <div class="card-body">
      <h4 class="card-title">{{ news['title'] }}</h4>
      <h6 class="card-subtitle mb-2">{{ news['modified_at'] }}</h6>
      <p class="card-text">{{ news['message'] }}</p>
      <button type="button"
        class="btn btn-sm btn-outline-secondary ms-auto"
        data-bs-toggle="modal"
        data-bs-target="#editNote"
        @click="$emit('edit-news', news)"
        v-if="$store.getters.hasPerm('change_news')">
        Editovat
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: {}
  },
  // computed: {
  //   formattedDate() {
  //     const date = new Date(this.date);
  //     return date.toLocaleDateString("cs-CZ", {
  //       day: "numeric",
  //       month: "numeric",
  //       year: "numeric"
  //     }).replace(/\//g, ".");
  //   }
  // }
};
</script>

<style scoped>
.card {
  margin: 20px auto;
}
</style>
